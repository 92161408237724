import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalNotesProps {
  show: boolean;
  onHide: () => void;
}
const ModalDerivarOirsEndComponents: React.FC<ModalNotesProps> = ({ show, onHide }) => {
  const [isAdmin] = useState('');
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--checked"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Perfil enviado a derivación OIRS</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {isAdmin == 'admin' ? <>
          <p className="text-24">Has derivado a <strong>Paulina</strong> al perfil de una especialista.</p>
          <p className="text-24">La especialista seleccionada recibirá su información y podrá ponerse en contacto con la mujer.</p>
        </> : <>
          <p className="text-24">Has solicitado que <strong>Paulina</strong> sea derivada a un reclamo OIRS.</p>
          <p className="text-24">En breve una Acompañante revisará la solicitud para iniciar el reclamo correspondiente.</p>
        </>}
        
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="submit" className="btn btn--type1 " onClick={onHide}>Regresar a ficha</button>
        </div>
       
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarOirsEndComponents;
