export const REGIONES_DATA = [
  {
    "id": 1,
    "label": "Arica y Parinacota",
  },
  {
    "id": 2,
    "label": "Tarapacá",
  },
  {
    "id": 3,
    "label": "Antofagasta",
  },
  {
    "id": 4,
    "label": "Atacama",
  },
  {
    "id": 5,
    "label": "Coquimbo",
  },
  {
    "id": 6,
    "label": "Valparaíso",
  },
  {
    "id": 7,
    "label": "Región del Libertador Gral. Bernardo O’Higgins",
  },
  {
    "id": 8,
    "label": "Región del Maule",
  },
  {
    "id": 9,
    "label": "Región de Ñuble",
  },
  {
    "id": 10,
    "label": "Región del Biobío",
  },
  {
    "id": 11,
    "label": "Región de la Araucanía",
  },
  {
    "id": 12,
    "label": "Región de Los Ríos",
  },
  {
    "id": 13,
    "label": "Región de Los Lagos",
  },
  {
    "id": 14,
    "label": "Región Aisén del Gral. Carlos Ibáñez del Campo",
  },
  {
    "id": 15,
    "label": "Región de Magallanes y de la Antártica Chilena",
  },
  {
    "id": 16,
    "label": "Región Metropolitana de Santiago",
  }
]

