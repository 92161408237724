import React, { useEffect, useState } from 'react';
import FooterShared from '../../../shared/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import Swal from 'sweetalert2';
import { DateRangePicker } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import TagsManagerComponent from '../../../components/Forms/tagsManager/tagsManager.component';
import GestoraTableComponent from '../../../components/Elements/DataTable/gestoraTable.component';
import CardWrapperComponent from '../../../components/Cards/cardWrapp.component';
import cards from '../../../utils/cardsGestora.json';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getSession } from '../../../utils/helpers';
import { IUser, UserSteps } from '../../../types/userInterface';
import { searchUsers } from '../../../api/usersApi';

const AllWomenScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [userSearchTerms, setUserSearchTerms] = useState<
    Partial<IUser & { createdAtRange?: { startDate: Date; endDate: Date } }>
  >({});
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState({
    totalDocs: 0,
    page: 1,
    limit: 20,
    totalPages: 0,
  });

  const navigate = useNavigate();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/iniciar-sesion');
        return;
      }
      const result = await searchUsers(
        session.token,
        { searchText, ...userSearchTerms },
        {
          page: pagination.page,
          limit: pagination.limit,
        }
      );

      if (result) {
        setUsers(result.docs);
        setPagination({
          totalDocs: result.totalDocs || 0,
          page: result.page || 1,
          limit: result.limit,
          totalPages: result.totalPages || 1,
        });
      }
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
      setError('Error al obtener los usuarios');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [searchText, userSearchTerms, pagination.page, pagination.limit]);

  return (
    <>
      <HeaderAdminShared />
      {loading && <LoaderComponent />}
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom40 row">
            <div className="col-12 mb-3">
              <a href="/administrador-inicio" className="btn--back">
                <i className="icon icon--back"></i> Regresar
              </a>
            </div>
            <div className="title-section module-bottom40 col-12">
              <h1 className="h2">Registro de mujeres</h1>
            </div>
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar mujer</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por: nombre, estatus, etc"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Búsqueda avanzada </Accordion.Header>
                    <Accordion.Body>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Calendarios
                        </label>
                        <Accordion className="accordion-inner">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Selecciona una fecha{' '}
                            </Accordion.Header>
                            <Accordion.Body>
                              <DateRangePicker
                                onChange={(value) => {
                                  console.log('DateRangePicker', value);
                                  const startDate = value?.[0];
                                  const endDate = value?.[1];
                                  if (startDate && endDate) {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: {
                                        startDate: startDate,
                                        endDate: endDate,
                                      },
                                    });
                                  } else {
                                    setUserSearchTerms({
                                      ...userSearchTerms,
                                      createdAtRange: undefined,
                                    });
                                  }
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Etiquetas
                        </label>
                        <TagsManagerComponent
                          admin={{}}
                          user={userSearchTerms}
                          handleAddition={(tags) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              tags: tags,
                            });
                          }}
                        />
                      </div>
                      <div className="form-row">
                        <label className="form-label label--title">
                          Estado
                        </label>
                        <select
                          className="form-select form--small"
                          aria-label="Estado"
                          defaultValue={'default'}
                          value={`${userSearchTerms.step || 'default'}`}
                          name="estado"
                          onChange={(e) => {
                            setUserSearchTerms({
                              ...userSearchTerms,
                              step: Number(e.target.value),
                            });
                          }}
                        >
                          <option value="default">Selecciona un estado</option>
                          <option value={UserSteps.INCIDENCIAS}>
                            Registrada
                          </option>
                          <option value={UserSteps.MI_PROCESO}>
                            En espera
                          </option>
                          <option value={UserSteps.ENTREGA}>En entrega</option>
                          <option value={UserSteps.ENTREGA_SUCCESS}>
                            Entrega realizada
                          </option>
                          <option value={UserSteps.PENDING_EVALUATION}>
                            Evaluación pendiente
                          </option>
                          <option value={UserSteps.PROCESS_COMPLETE}>
                            Proceso completado
                          </option>
                        </select>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <GestoraTableComponent
                users={users}
                pagination={pagination}
                setPagination={setPagination}
              />
            </div>
          </div>
          <CardWrapperComponent title="Lo que hacemos en OLA" cards={cards} />
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default AllWomenScreen;
