import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import PersonalDataComponent from '../../../../components/Forms/userDetail/personalData.component';
import DemographicDataComponent from '../../../../components/Forms/userDetail/demographicData.component';
import HealthDataComponent from '../../../../components/Forms/userDetail/healthData.component';
import InfoDataComponent from '../../../../components/Forms/userDetail/infoData.component';
import { IUser, UserStatus } from '../../../../types/userInterface';
import { IAdmin } from '../../../../types/adminInterface';
import { getSession } from '../../../../utils/helpers';
import { getUser, updateUser } from '../../../../api/usersApi';
import LoaderComponent from '../../../../components/Loader/loader.component';
import HeaderAdminShared from '../../../../shared/headerAdmin.shared';
import FooterShared from '../../../../shared/footer.shared';

const EditUserScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [session, setSession] = useState<Partial<IAdmin>>({});

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del usuario');
      }
      const session = getSession();
      const response = await getUser(id, session?.token || '');

      if (session) {
        setSession(session);
      }
      if (response.status === UserStatus.BLOCKED) {
        navigate('/mi-colectiva');
      }
      setUser(response);
    } catch (error) {
      setError('Error al obtener la información del usuario');
    } finally {
      setLoading(false);
    }
  };

  const updateAmiga = async (updatedUser: Partial<IUser>) => {
    try {
      setLoading(true);
      const response = await updateUser(
        user._id || '',
        updatedUser,
        session?.token || ''
      );
      setUser(response);
      navigate(`/editar-usuario-finalizado/${user._id || ''}`);
    } catch (error) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser();
  }, []);

  return (
    <>
      {loading && <LoaderComponent />}
      <HeaderAdminShared />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="mb-4 col-12">
                <a
                  href={`/detalle-usuario/${user._id || ''}`}
                  className="btn--back"
                >
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1>Editar información</h1>
                <Accordion defaultActiveKey="0" className="accordion-regular">
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Información personal</Accordion.Header>
                    <Accordion.Body>
                      <PersonalDataComponent
                        user={user}
                        setUser={setUser}
                        readonly={false}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Datos Sociodemográficos</Accordion.Header>
                    <Accordion.Body>
                      <DemographicDataComponent
                        user={user}
                        setUser={setUser}
                        readonly={false}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Salud y Cuidados</Accordion.Header>
                    <Accordion.Body>
                      <HealthDataComponent
                        user={user}
                        token={session?.token || ''}
                        readonly={false}
                        setUser={setUser}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Contexto</Accordion.Header>
                    <Accordion.Body>
                      <InfoDataComponent
                        user={user}
                        readonly={false}
                        setUser={setUser}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="mt-3 col-12">
                  <a
                    className="btn btn--type1 btn--100"
                    onClick={(e) => {
                      e.preventDefault();
                      updateAmiga(user);
                    }}
                  >
                    Guardar cambios
                  </a>
                </div>
              </div>
              <aside className="auto-row__right col-md-7 col-12 hide-mobile">
                <figure className="image-270">
                  <img
                    src="/assets/images/illustration/phone.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EditUserScreen;
