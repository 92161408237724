import React from 'react';

interface ColectivaData {
  id?: number;
  name: string;
  women?: number;
  gestora?: string;
  asesora?: string;
  acompanantes?: string;
  psicologas?: string;
  aporte?: number;
  url?: string;
}

interface ColectivasTableProps {
  colectiva: ColectivaData;
}

const ColectivasTable: React.FC<ColectivasTableProps> = ({ colectiva }) => {
  console.log('ColectivasTable', colectiva);
  return (
    <table className="accordion-table">
      <tr>
        <td>
          <span>
            <strong>Mujeres</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">{colectiva.women || 'Sin asignar'}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Asesora Legal</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">{colectiva.asesora || 'Sin asignar'}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Acompañante</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            {colectiva.acompanantes || 'Sin asignar'}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Psicóloga</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            {colectiva.psicologas || 'Sin asignar'}
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Gestora Comunitaria</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">{colectiva.gestora || 'Sin asignar'}</span>
        </td>
      </tr>
      <tr>
        <td>
          <span>
            <strong>Aporte Total</strong>
          </span>
        </td>
        <td className="text-right">
          <span className="text-14">
            $
            {(colectiva.aporte || 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            CLP
          </span>
        </td>
      </tr>
      <tr>
        <td></td>
        <td className="text-right">
          <a
            href={`/colectiva/${colectiva.id}`}
            className="text-decoration-none"
          >
            Ver detalle
          </a>
        </td>
      </tr>
    </table>
  );
};

export default ColectivasTable;
