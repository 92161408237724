export const GESTORAS_DATA = [
  {
    id: 1,
    text: 'Rafaela',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 2,
    text: 'Valeria',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 3,
    text: 'Karina',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 4,
    text: 'Romina',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 5,
    text: 'Karla',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 6,
    text: 'Maria',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 7,
    text: 'Laura',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 8,
    text: 'Ana',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 9,
    text: 'Sofia',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 10,
    text: 'Isabella',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 11,
    text: 'Camila',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 12,
    text: 'Valentina',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 13,
    text: 'Luciana',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 14,
    text: 'Gabriela',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 15,
    text: 'Fernanda',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 16,
    text: 'Daniela',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 17,
    text: 'Mariana',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 18,
    text: 'Carolina',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 19,
    text: 'Paula',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  },
  {
    id: 20,
    text: 'Juliana',
    position: "Acompañante",
    url: "/detalle-acompanantes"
  }
]