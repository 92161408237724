import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface PersonalDataProps {
}

const PersonalDataComponent: React.FC<PersonalDataProps> = () => {

  return (
    <div className="form-block">
       <form >
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Nombre de usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Nombre del usuario"
            value={'Paulina Pimentel'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Correo
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Esta información es importante para poder tener un medio de contacto con el usuario. 
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="tucorreo@dominio.com.cl"
            value={'paulina@dominio.com'}
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone" className="form-label label--icon">
            Teléfono
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                   Esta información es importante para mantenernos en contacto a través de llamadas y/o chats.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="phone"
            className="form-control"
            id="phone"
            placeholder="+1234567890"
            value={'9875312543'}
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalDataComponent;