import React, {useState} from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {NACIONALIDAD_DATA} from '../../../utils/nacionalidad';
import {REGIONES_DATA} from '../../../utils/regiones';
import {NIVEL_DATA} from '../../../utils/nivelEducativo';


interface AcompananteDataProps {
}

interface NivelEducativo {
  title: string;
  levels: Level[];
}
interface Level {
  id: number;
  label: string;
}

const AcompananteDataComponent: React.FC<AcompananteDataProps> = () => {
  const [nationality, setNationality] = useState<string>('Chile');
  const [RegionZone, setRegionZone] = useState<string>('6');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nation, setNation] = useState(NACIONALIDAD_DATA);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [region, setRegion] = useState(REGIONES_DATA);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [nivelEducativo, setNivelEducativo] = useState<number>(2);

  const handleNationalityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNationality(e.target.value);
  };
  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRegionZone(e.target.value);
  };

  const handleNivelEducativoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(e.target.value, 10);
    setNivelEducativo(selectedId);
  };


  return (
    <div className="form-block">
      <form >
      <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre o seudónimo <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Nombre del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Escribe el nombre"
            value="Rafaela"
          />
        </div>
        <div className="form-row">
          <label htmlFor="date" className="form-label label--icon">
            Fecha de nacimiento <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Fecha de nacimiento.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="date"
            className="form-control"
            id="date"
            placeholder="dd/mm/aaaa"
            value="17/09/1991"
          />
        </div>
        <div className="form-row">
          <label htmlFor="age" className="form-label label--icon">
            Edad <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Edad del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="number"
            className="form-control"
            id="age"
            placeholder="Coloca los años cumplidos"
            value="32"
          />
        </div>
        <div className="form-row">
          <label htmlFor="nacionalidad" className="form-label label--icon">
            Nacionalidad <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Nacionalidad del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="nacionalidad"
            className="form-select"
            aria-label="Tu nacionalidad"
            onChange={handleNationalityChange}
            value={nationality}
          >
            <option value="default" disabled>Elige una de las opciones</option>
            {nation.map((country) => (
              <option key={country.id} value={country.id}>{country.label}</option>
            ))}
          </select>
        </div>
       
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Región del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            id="region"
            className="form-select"
            aria-label="Tu región"
            onChange={handleRegionChange}
            value={RegionZone}
          >
            <option value="default" disabled>Elige una de las opciones</option>
            {region.map((regionItem) => (
              <option key={regionItem.id} value={regionItem.id}>{regionItem.label}</option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="address" className="form-label label--icon">
            Dirección <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Dirección del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            id="address"
            type="text"
            className="form-control"
            placeholder="Escribe calle y número"
            value="Calle 54 123"
          />
        </div>
        <div className="form-row">
          <label htmlFor="cp" className="form-label label--icon">
            Código postal <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Código postal del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            id="cp"
            type="number"
            className="form-control"
            placeholder="Escribe el C.P."
            value="97123"
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion" className="form-label label--icon">
            Ocupaciones principales (trabajo, estudio) <small className="required">*</small>
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip>
                 Ocupaciones del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            id="ocupacion"
            type="text"
            className="form-control"
            placeholder="Escribe tu ocupación principal"
            value="Empleada"
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel" className="form-label label--icon">
              Nivel educativo <small className="required">*</small>
              <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip>
                  Nivel educativo del usuario.
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
            </label>
            <select
              id="nivel"
              className="form-select"
              aria-label="Tu nivel educativo"
              defaultValue={'default'}
              name="NivelEducativo"
              onChange={handleNivelEducativoChange}
              value={nivelEducativo}
            >
              <option value="default" disabled>Elige una de las opciones</option>
              {NIVEL_DATA.map((nivel: NivelEducativo) => (
                <optgroup key={nivel.title} label={nivel.title}>
                  {nivel.levels.map((opcion: Level) => (
                    <option key={opcion.id} value={opcion.id}>{opcion.label}</option>
                  ))}
                </optgroup>
              ))}
        </select>
        </div>
      </form>
    </div>
  );
};

export default AcompananteDataComponent;