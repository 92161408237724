import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import FooterShared from '../../../shared/footer.shared';
import ReactSearchAutocomplete from 'react-search-autocomplete/dist/components/ReactSearchAutocomplete';
import UserTableComponent from '../../../components/Elements/DataTable/userTable.component';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import {
  AdminPrincipalRol,
  AdminSteps,
  IAdmin,
} from '../../../types/adminInterface';
import { getSession } from '../../../utils/helpers';
import { searchAdmins } from '../../../api/adminApi';
import LoaderComponent from '../../../components/Loader/loader.component';

const EquipoScreen: React.FC = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [principalRolIn, setPrincipalRolIn] = useState<AdminPrincipalRol[]>([]);
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [pagination, setPagination] = useState({
    totalDocs: 0,
    page: 1,
    limit: 20,
    totalPages: 0,
  });

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmins = async () => {
    try {
      if (searchText === '') {
        setLoading(true);
      }

      const session = getSession();
      if (!session || !session.token) {
        navigate('/iniciar-sesion');
        return;
      }

      const result = await searchAdmins(
        pagination.page,
        pagination.limit,
        {
          notPrincipalRol: AdminPrincipalRol.ADMIN,
          stepGte: AdminSteps.GET_OLA_ACCOUNT,
          searchText,
          principalRolIn,
        },
        session.token
      );

      setAdmins(result.docs);
      setPagination({
        totalDocs: result.totalDocs || 0,
        limit: result.limit || 50,
        page: result.page || 1,
        totalPages: result.totalPages || 0,
      });
      setLoading(false);
    } catch (error) {
      setError('Error al obtener los administradores');
    } finally {
      setLoading(false);
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    fetchAdmins();
  }, [searchText, principalRolIn, pagination.page]);

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row mb-4">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/equipo-de-las-amigas" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <h2 className="h2 mb-4">Equipo OLA</h2>
              <div className="col-12">
                <div className="form-search-complete mb-4">
                  <div className="autocomplete-search search--100">
                    <ReactSearchAutocomplete
                      items={[]}
                      autoFocus
                      onSearch={(keyword) => setSearchText(keyword)}
                      placeholder="Buscar colectiva"
                      className="autocomplete-search__input"
                      showItemsOnFocus={false}
                      showNoResults={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-body module-top40">
          <div className="row">
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                {!isMobileView ? (
                  <>
                    <h4 className="mb-0">¿Qué quieres buscar?</h4>
                    <hr />
                    <ul className="search-checks-list">
                      {[
                        AdminPrincipalRol.ACOMPANANTE,
                        AdminPrincipalRol.ASESORA_LEGAL,
                        AdminPrincipalRol.GESTORA,
                        AdminPrincipalRol.PSICOLOGA,
                      ].map((rol) => (
                        <li key={rol}>
                          <div className="form-check check--small">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id={rol}
                              onClick={(e) => {
                                const isChecked = e.currentTarget.checked;
                                if (isChecked) {
                                  setPrincipalRolIn([...principalRolIn, rol]);
                                } else {
                                  setPrincipalRolIn(
                                    principalRolIn.filter(
                                      (item) => item !== rol
                                    )
                                  );
                                }
                              }}
                            />
                            <label className="form-check-label" htmlFor={rol}>
                              {rol}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>¿Qué quieres buscar?</Accordion.Header>
                      <Accordion.Body>
                        <ul className="search-checks-list">
                          {[
                            AdminPrincipalRol.ACOMPANANTE,
                            AdminPrincipalRol.ASESORA_LEGAL,
                            AdminPrincipalRol.GESTORA,
                            AdminPrincipalRol.PSICOLOGA,
                          ].map((rol) => (
                            <li key={rol}>
                              <div className="form-check check--small">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={rol}
                                  onClick={(e) => {
                                    const isChecked = e.currentTarget.checked;
                                    if (isChecked) {
                                      setPrincipalRolIn([
                                        ...principalRolIn,
                                        rol,
                                      ]);
                                    } else {
                                      setPrincipalRolIn(
                                        principalRolIn.filter(
                                          (item) => item !== rol
                                        )
                                      );
                                    }
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={rol}
                                >
                                  {rol}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <h4 className="mb-4">Resultados</h4>
              <UserTableComponent
                admins={admins}
                pagination={pagination}
                setPagination={setPagination}
              />
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EquipoScreen;
