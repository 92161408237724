import React, {useState, useEffect} from 'react';
import DataTable, { TableColumn }  from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component'
import { Accordion } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionBody from 'react-bootstrap/AccordionBody';


interface DataRow {
  id: number;
  ficha: string;
  name: string;
  weeks: string;
  derivada: string;
  status: string;
  url: string;
  isNew: boolean;
  time: string;
}

const ColectivaTableComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);
  const rowsPerPage = 10;
  const [rows, setRows] = useState<DataRow[]>([
    {
      id: 1,
      ficha: '12894',
      name: 'Paulina Pimentel',
      weeks: '10 + 3 días',
      derivada: 'OLA',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: true,
      time: '11:10 am 11/12/23',
    },
    {
      id: 2,
      ficha: '12345',
      name: 'Maria Rodriguez',
      weeks: '8 + 2 días',
      derivada: 'OLA',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: false,
      time: '09:30 am 10/12/23',
    },
    {
      id: 3,
      ficha: '67890',
      name: 'Ana Lopez',
      weeks: '12 + 1 día',
      derivada: 'OLA',
      status: 'en-espera',
      url: '/detalle-usuario',
      isNew: true,
      time: '02:45 pm 10/12/23',
    },
    {
      id: 4,
      ficha: '54321',
      name: 'Laura Martinez',
      weeks: '9 + 4 días',
      derivada: 'OLA',
      status: 'derivada',
      url: '/detalle-usuario',
      isNew: false,
      time: '08:20 am 11/12/23',
    },
    {
      id: 5,
      ficha: '98765',
      name: 'Sofia Garcia',
      weeks: '11 + 3 días',
      derivada: 'OLA',
      status: 'entrega',
      url: '/detalle-usuario',
      isNew: false,
      time: '03:15 pm 11/12/23',
    },
    {
      id: 6,
      ficha: '13579',
      name: 'Isabella Hernandez',
      weeks: '10 + 2 días',
      derivada: 'OLA',
      status: 'finalizo',
      url: '/detalle-usuario',
      isNew: true,
      time: '10:50 am 12/12/23',
    },
    {
      id: 7,
      ficha: '24680',
      name: 'Valentina Gonzalez',
      weeks: '7 + 5 días',
      derivada: 'OLA',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: false,
      time: '01:40 pm 12/12/23',
    },
    {
      id: 8,
      ficha: '86420',
      name: 'Camila Perez',
      weeks: '13 + 2 días',
      derivada: 'OLA',
      status: 'en-espera',
      url: '/detalle-usuario',
      isNew: true,
      time: '09:25 am 13/12/23',
    },
    {
      id: 9,
      ficha: '97531',
      name: 'Luciana Torres',
      weeks: '8 + 3 días',
      derivada: 'OLA',
      status: 'derivada',
      url: '/detalle-usuario',
      isNew: false,
      time: '04:30 pm 13/12/23',
    },
    {
      id: 10,
      ficha: '12345',
      name: 'Valeria Ramirez',
      weeks: '11 + 1 día',
      derivada: 'OLA',
      status: 'entrega',
      url: '/detalle-usuario',
      isNew: false,
      time: '10:15 am 14/12/23',
    },
    {
      id: 11,
      ficha: '67890',
      name: 'Gabriela Castro',
      weeks: '9 + 4 días',
      derivada: 'OLA',
      status: 'finalizo',
      url: '/detalle-usuario',
      isNew: true,
      time: '03:55 pm 14/12/23',
    },
    {
      id: 12,
      ficha: '54321',
      name: 'Daniela Morales',
      weeks: '12 + 3 días',
      derivada: 'OLA',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: false,
      time: '11:30 am 15/12/23',
    },
    {
      id: 13,
      ficha: '98765',
      name: 'Mariana Silva',
      weeks: '10 + 2 días',
      derivada: 'OLA',
      status: 'en-espera',
      url: '/detalle-usuario',
      isNew: true,
      time: '02:20 pm 15/12/23',
    },
    {
      id: 14,
      ficha: '13579',
      name: 'Fernanda Rios',
      weeks: '8 + 5 días',
      derivada: 'OLA',
      status: 'derivada',
      url: '/detalle-usuario',
      isNew: false,
      time: '09:10 am 16/12/23',
    },
    {
      id: 15,
      ficha: '24680',
      name: 'Carolina Ortega',
      weeks: '13 + 1 día',
      derivada: 'OLA',
      status: 'entrega',
      url: '/detalle-usuario',
      isNew: false,
      time: '04:05 pm 16/12/23',
    },
    {
      id: 16,
      ficha: '86420',
      name: 'Antonella Mendez',
      weeks: '11 + 2 días',
      derivada: 'OLA',
      status: 'finalizo',
      url: '/detalle-usuario',
      isNew: true,
      time: '10:50 am 17/12/23',
    },
    {
      id: 17,
      ficha: '97531',
      name: 'Victoria Castro',
      weeks: '9 + 3 días',
      derivada: 'OLA',
      status: 'acompanada',
      url: '/detalle-usuario',
      isNew: false,
      time: '03:45 pm 17/12/23',
    },
    {
      id: 18,
      ficha: '12345',
      name: 'Emily Torres',
      weeks: '12 + 1 día',
      derivada: 'OLA',
      status: 'en-espera',
      url: '/detalle-usuario',
      isNew: false,
      time: '09:30 am 18/12/23',
    },
    {
      id: 19,
      ficha: '67890',
      name: 'Ximena Ramirez',
      weeks: '10 + 4 días',
      derivada: 'OLA',
      status: 'derivada',
      url: '/detalle-usuario',
      isNew: true,
      time: '02:25 pm 18/12/23',
    },
    {
      id: 20,
      ficha: '54321',
      name: 'Nicole Silva',
      weeks: '8 + 3 días',
      derivada: 'OLA',
      status: 'entrega',
      url: '/detalle-usuario',
      isNew: false,
      time: '08:15 am 19/12/23',
    },
  ]);

  const handleNameClick = (id: number) => {
    const updatedRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, isNew: false };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const columns: TableColumn<DataRow>[] = [
    {
      name: 'No. Ficha',
      selector: (row: DataRow) => row.ficha,
    },
    {
      name: 'Nombre',
      selector: (row: DataRow) => row.name,
      format: (row: DataRow) => {
        return (
          <a href={row.url} 
             className={`text-green400 text-uppercase ${row.isNew ? 'new' : ''}`}
             onClick={() => handleNameClick(row.id)}>
            <strong>{row.name}</strong>
          </a>
        );
      }
    },
    {
      name: 'Semanas',
      selector: (row: DataRow) => row.weeks,
    },
    {
      name: 'Derivada de',
      selector: (row: DataRow) => row.derivada,
    },
   
    {
      name: 'Estado',
      selector: (row: DataRow) => row.status,
      format: (row: DataRow) => {
        if (row.status === 'acompanada') {
          return (
            <span className="badge badge--status badge--status-acompanada">Acompañada</span>
          );
        } else if (row.status === 'en-espera') {
          return (
            <span className="badge badge--status badge--status-info">En espera</span>
          );
        } else if (row.status === 'derivada') {
          return (
            <span className="badge badge--status badge--status-acompanada">Derivada</span>
          );
        } else if (row.status === 'entrega') {
          return (
            <span className="badge badge--status badge--status-success">Entrega</span>
          );
        } else if (row.status === 'finalizo') {
          return (
            <span className="badge badge--status badge--status-done">Finalizó</span>
          );
        } else {
          return (
            <span className="badge badge--status badge--status-info">{row.status}</span>
          );
        }
       
      }
    },
    {
      name: 'Hora de registro',
      selector: (row: DataRow) => row.time,
      format: (row: DataRow) => {
        // Dividir la cadena en la hora y la fecha
        const parts = row.time.split(' ');
        const time = `${parts[0]} ${parts[1]}`; // Hora (11:10 am)
        const date = parts.slice(2).join(' '); // Fecha (11/12/23)

        return (
          <div className="data-table__time">
            <span style={{ marginRight: 8 }}>{time}</span>
            <span>{date}</span>
          </div>
        );
      }
    },
  ];
  
  const conditionalRowStyles = [
    {
      when: (row: DataRow) => row.isNew,
      style: {
        backgroundColor: '#FCEEF2',
      }
    }
  ];

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const formatStatus = (status: string) => {
    switch (status) {
      case 'acompanada':
        return <span className="badge badge--status badge--status-acompanada">Acompañada</span>;
      case 'en-espera':
        return <span className="badge badge--status badge--status-info">En espera</span>;
      case 'derivada':
        return <span className="badge badge--status badge--status-acompanada">Derivada</span>;
      case 'entrega':
        return <span className="badge badge--status badge--status-success">Entrega</span>;
      case 'finalizo':
        return <span className="badge badge--status badge--status-done">Finalizó</span>;
      default:
        return <span className="badge badge--status badge--status-info">{status}</span>;
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
       {!isMobileView ? (
        <DataTable
            columns={columns}
            data={rows}
            conditionalRowStyles={conditionalRowStyles}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationComponent={props => {
              const handleChangePage = (page: number) => {
                console.log('Changing page to', page);
                // Asume que props.onChangePage realmente espera un solo argumento.
                // Esto es para diagnóstico; revisa la consola para ver qué se registra cuando cambias de página.
                (props.onChangePage as (page: number) => void)(page);

              };
              
              return (
                <div className="data-paginator">
                  <CustomPagination
                    rowsPerPage={props.rowsPerPage} // eslint-disable-next-line react/prop-types
                    rowCount={props.rowCount} // eslint-disable-next-line react/prop-types
                    onChangePage={handleChangePage}
                    currentPage={currentPage}
                  />
                </div>
              );
            }}
            
            onChangePage={page => setCurrentPage(page)}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={rows.length}
          />

         ) : (

          <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p><strong>Nombre</strong></p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {rows.map((row, index) => (
              
               (() => {
                  // Dividir la cadena en la hora y la fecha
                  const parts = row.time.split(' ');
                  const time = `${parts[0]} ${parts[1]}`; // Hora (11:10 am)
                  const date = parts.slice(2).join(' '); // Fecha (11/12/23)
                return(
                  <AccordionItem eventKey={String(index)} key={row.id}>
                  <AccordionHeader>{row.name}</AccordionHeader>
                  <AccordionBody>
                    <table>
                      <tr>
                        <td><strong>No. Ficha:</strong></td>
                        <td className="text-center">{row.ficha}</td>
                      </tr>
                      <tr>
                        <td><strong>Semanas:</strong></td>
                        <td className="text-center">{row.weeks}</td>
                      </tr>
                      <tr>
                        <td><strong>Derivada de:</strong></td>
                        <td className="text-center">{row.derivada}</td>
                      </tr>
                     
                    
                      <tr>
                        <td><strong>Estado:</strong></td>
                        <td className="text-center">{formatStatus(row.status)}</td>
                      </tr>
                      <tr>
                        <td><strong>Hora de registro:</strong></td>
                        <td className="text-center">
                          <div className="data-table__time">
                              <span style={{ marginRight: 8 }}>{time}</span>
                              <span>{date}</span>
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-center">
                          <a href="/detalle-usuario" className="btn btn--type2">
                            <strong>Ver ficha</strong>
                          </a>
                        </td>
                      </tr>
                    </table>
                  </AccordionBody>
                </AccordionItem>
                )
              })()
            ))}
          </Accordion>
        </section>
         )}
      
     
    </div>
  );
};

export default ColectivaTableComponent;
