import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  organizer: string;
  date: string;
  url: string;
}

const GestoraItemDateComponent: React.FC<Props> = ({
  title,
  organizer,
  date,
  url,
}) => {
  return (
    <Link to={url} className="btn btn--taller">
      <figure className="icon-user-pic">
        <img src="/assets/images/icons/user-pic-2.svg" alt="User" />
      </figure>
      <p>
        <strong className="text-purple500">{title}</strong>
        <span>
          {organizer} • <small className="text-16 text-gray600">{date}</small>
        </span>
      </p>
    </Link>
  );
};

export default GestoraItemDateComponent;
