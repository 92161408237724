import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalDerivarProps {
  show: boolean;
  onHide: () => void;
  onEnd: () => void;
}

const ModalDerivarProfesionalComponents: React.FC<ModalDerivarProps> = ({ show, onHide, onEnd }) => {


  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Una profesional solicitó derivar a esta mujer a otra profesional</h2>
          <p className="text-24 text-center">Estos son los motivos expuestos por la acompañante:</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert--soft-success">
          <span className="material-icons-outlined">flag</span>
          <article>
            <p><strong>Derivar a profesional dentro de OLA</strong></p>
          </article>
        </div>
        <ul className="check-list">
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Necesita ayuda adicional</p>
          </li>
          <li>
            <span className="material-icons-outlined">done</span>
            <p>No es mi área de especialidad</p>
          </li>
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Otras razones</p>
          </li>
        
        </ul>
        <div className="alert alert--info">
          <article>
            <p className="text-gray600"><strong>Observaciones hechas por la especialista:</strong></p>
            <p>Atención psicológica Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el por qué de esta decisión.</p>
          </article>
        </div>
        <div className="form-row">
        <label className="form-label label--icon">
          Selecciona a la profesional de OLA a la que la quieras derivar <small className="required">*</small>
        </label>
        <select
          className="form-select requiredField"
          aria-label="Selecciona a la profesional de Las Amigas a la que la quieras derivar"
          defaultValue={'default'}
          name="profesional"
        >
          <option value="default" disabled>Elige una de las opciones</option>
          <option>Option 1</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </select>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-7 mx-auto col-12 d-flex justify-content-center mb-3">
          
           <button type="submit" className="btn btn--type1 btn--100"
              onClick={() => {
                onHide();
                onEnd();
              }}
            >
              Derivar a profesional seleccionada
            </button>
         
        </div>
        <div className="col-md-7 mx-auto col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type2 btn--100" onClick={onHide}>
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarProfesionalComponents;
