import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import FooterShared from '../../../shared/footer.shared';
import SolicitudDetailComponent from '../../../components/Forms/solicitudDetail/solicitudDetail.component';
import { AdminSteps, IAdmin } from '../../../types/adminInterface';
import LoaderComponent from '../../../components/Loader/loader.component';
import { getAdmin, updateAdmin } from '../../../api/adminApi';
import { getSession } from '../../../utils/helpers';

interface Props {
  // Define your component props here
}

const SolicitudDetailScreen: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});

  const { id } = useParams();
  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmin = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/iniciar-sesion');
        return;
      }
      const response = await getAdmin(id || '', session.token);
      if (response) {
        setAdmin(response);
      }
    } catch (error) {
      setError('Error al obtener la solicitud');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        navigate('/iniciar-sesion');
        return;
      }
      const response = await updateAdmin(
        id || '',
        {
          step: AdminSteps.GET_OLA_ACCOUNT,
        },
        session.token
      );
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Solicitud actualizada',
          text: 'La solicitud ha sido actualizada correctamente',
        }).then(() => {
          navigate('/solicitudes');
        });
      }
    } catch (error) {
      setError('Error al obtener la solicitud');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  return (
    <>
      <HeaderAdminShared />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="auth-row row">
              <div className="col-12 mb-4">
                <a href="/solicitudes" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-md-5 col-12">
                <h1 className="mb-3">Solicitud de gestora nueva</h1>
                <p className="text-24 mb-4">
                  Revisa la información para aprobar o rechazar la solicitud.
                </p>
                {admin && <SolicitudDetailComponent admin={admin} />}
                <div className="col-12 mt-3">
                  <a
                    href="#"
                    className="btn btn--type1 btn--100"
                    onClick={(e) => {
                      e.preventDefault();
                      Swal.fire({
                        title: '¿Estás seguro de aceptar la solicitud?',
                        text: 'Una vez aceptada, no podrás volver a editar la solicitud.',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Aceptar',
                        cancelButtonText: 'Cancelar',
                      }).then((response) => {
                        if (response.isConfirmed) {
                          handleUpdate();
                        }
                      });
                    }}
                  >
                    Aceptar solicitud y programar entrevista
                  </a>
                </div>
                <div className="col-12 mt-3">
                  <button type="button" className="btn btn--type2 btn--100">
                    Rechazar
                  </button>
                </div>
              </div>
              <aside className="d-flex justify-content-center hide-mobile col-md-7 col-12">
                <figure className="image-350">
                  <img
                    src="/assets/images/illustration/computer.png"
                    alt="Women"
                  />
                </figure>
              </aside>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default SolicitudDetailScreen;
