import React from 'react';
import { useLocation } from 'react-router-dom';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import GestoraStep1Component from '../../../components/Auth/Gestora/gestoraStep1.component';
import { IAdmin } from '../../../types/adminInterface';

const GestoraComunitariaIndexScreen: React.FC = () => {
  const { state } = useLocation();
  const admin: Partial<IAdmin> = state?.admin || {};

  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="module-bottom40 col-12">
              <StepperComponent activeStep={1} />
            </div>
            <div className="col-lg-5 col-12">
              <h1>Regístrate como gestora</h1>
              <article className="text-20 col-12 col-reset">
                <p>
                  Para formar parte de nosotras como gestora comunitaria,
                  necesito hacerte algunas preguntas.
                </p>
                <p className="text-green350">
                  Recuerda que este proceso, así como tus datos están
                  protegidos.
                </p>
              </article>
              <h2 className="text-24">Tu información personal</h2>
              {admin && <GestoraStep1Component admin={admin} />}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestoraComunitariaIndexScreen;
