import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import { USERS_DATA } from '../../../utils/users';
import UserCardComponent from '../../../components/Elements/UserCard/userCard.component';

const AccompanimentScreen: React.FC= () => {
  const hasUsers = USERS_DATA.length > 0;
  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/inicio-acompanante" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
             
              {!hasUsers && (
                <>
                  <div className="col-12 d-flex justify-content-center">
                    <figure className="image-179">
                      <img src="/assets/images/illustration/404.png" alt="No tienes nuevos acompañamientos asignados" />
                    </figure>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <article>
                      <h1 className="h3 text-regular">No tienes nuevos acompañamientos asignados</h1>
                      <p>Pronto llegarán nuevas mujeres a las que podrás seguir ayudando.</p>
                      <a href="/inicio-acompanante" className="btn btn--type1">Ir al inicio</a>
                    </article>
                  </div>
                </>
              )}
              {hasUsers && (
                <div className="col-12 col-md-8 mx-auto mt-3">
                  <article className="text-center mb-4">
                    <h1 className="h2 ">Nuevas mujeres para acompañar</h1>
                    <p className="text-20">Se te asignaron {USERS_DATA.length} nuevos acompañamientos:</p>
                  </article>
                  <div className="row justify-content-center">
                    {USERS_DATA.map((user, index) => (
                      <div key={index} className="col-md-6 col-12 mb-4">
                        <UserCardComponent
                          name={user.name}
                          id={user.id}
                          registrationDate={user.registrationDate}
                          weeks={user.weeks}
                          origin={user.origin}
                          cause={user.cause}
                          commune={user.commune}
                          derivationReasons={user.derivationReasons}
                          referringProfessional={user.referringProfessional}
                          detailUrl={user.detailUrl}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  )
}

export default AccompanimentScreen;
