import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import TagsComponent from '../../../components/Forms/tags/tags.component';
import {TAGS_DATA} from '../../../utils/tags';
import ActionsAcompananteButtonComponent from '../../../components/Elements/Buttons/actionsAcompanante.component';

const ChatAdminScreen: React.FC = () => {
  const tagsShow = [
    'tag-10'
  ]; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter(tag => tagsShow.includes(tag.id));
  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-3">
                <a href="/detalle-acompanante" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="view-head__data col-12">
                <div className="row">
                  <div className="view-detail col-md-8 col-12">
                    <h1 className="text-40">Rafaela</h1>
                    <p className="text-20">Acompañante</p>
                    <div className="view-detail__tags">
                      <TagsComponent tags={tagsFiltered} />
                    </div>
                   
                  
                  </div>
                  <aside className="view-detail__action no-flex  col-md-4 col-12">
                    <div className="view-detail__action-col col-12 col-reset mb-3">
                      <ActionsAcompananteButtonComponent />
                    </div>
                  </aside>
                </div>
              </div>
              <div className="col-12">
                <div className="chatbox-placeholder"></div>
              </div>
            </div>
          
          
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default ChatAdminScreen;