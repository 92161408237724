import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { IUser, UserStatus } from '../../../../types/userInterface';

interface ModalDisabledComponentProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  onSuccess: (user: Partial<IUser>) => void;
  updateAmiga?: (user: Partial<IUser>) => Promise<void>;
}

const ModalDisabledComponent: React.FC<ModalDisabledComponentProps> = ({
  user,
  show,
  onHide,
  onSuccess,
  updateAmiga,
}) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateAmiga &&
      updateAmiga({
        _id: personalData._id,
        status: isChecked ? UserStatus.INACTIVE : UserStatus.ACTIVE,
      }).then(() => {
        onHide();
        setFormSubmittedSuccessfully(true);
        onSuccess({
          _id: personalData._id,
          status: isChecked ? UserStatus.INACTIVE : UserStatus.ACTIVE,
        });
      });
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    setPersonalData({
      ...personalData,
      status: isChecked ? UserStatus.INACTIVE : UserStatus.ACTIVE,
    });
  };

  useEffect(() => {
    if (user) {
      setPersonalData(user);
      setIsChecked(user.status === UserStatus.INACTIVE);
    }
  }, [user]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="text-center">
              <i className="icon icon--disabled"></i> Desactivar temporalmente
            </h2>
          </Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <h3 className="mb-5 text-center h4">
            ¿Deseas desactivar temporalmente esta cuenta?
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="pb-3 form-row d-flex justify-content-center">
              <Form.Check // prettier-ignore
                type="switch"
                id="disabled"
                label={isChecked ? 'Cuenta desactivada' : 'Cuenta activada'}
                checked={isChecked}
                onChange={handleSwitchChange}
              />
            </div>
            <p className="mb-5 text-center">
              Necesitamos tu confirmación, recuerda que puedes volver a activar
              esta cuenta cuando lo desees.
            </p>
            <div className="form-row d-flex justify-content-center">
              <button type="submit" className="btn btn--type1 btn--290">
                Confirmar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalDisabledComponent;
