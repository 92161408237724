import React from 'react';
import { IAdmin } from '../../../types/adminInterface';

interface Props {
  admin: Partial<IAdmin>;
}

const SolicitudDetailComponent: React.FC<Props> = ({ admin }) => {
  return (
    <div className="form-block">
      <form>
        <h2 className="text-24">Información personal</h2>
        <div className="form-row">
          <label htmlFor="name" className="form-label label--icon">
            Nombre y apellido <small className="required">*</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Escribe el nombre"
            value={`${admin.username || ''} ${admin.lastName || ''}`}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label label--icon">
            Correo electrónico <small className="required">*</small>
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="micorreo@email.com"
            value={admin.email || ''}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="phone" className="form-label label--icon">
            Número celular <small className="required">*</small>
          </label>
          <input
            type="tel"
            className="form-control"
            id="phone"
            placeholder="5512345678"
            value={admin.phone || ''}
            disabled
          />
        </div>
        <h2 className="text-24">Datos Sociodemográficos</h2>
        <div className="form-row">
          <label htmlFor="age" className="form-label label--icon">
            Edad <small className="required">*</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="age"
            placeholder="Coloca los años cumplidos"
            value={admin.birthday || ''}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="nacionalidad" className="form-label label--icon">
            Nacionalidad <small className="required">*</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="nacionalidad"
            placeholder="Tu nacionalidad"
            value={admin.country?.name || ''}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="region" className="form-label label--icon">
            Región <small className="required">*</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="region"
            placeholder="Tu región"
            value={admin.state?.name || 'N/A'}
            disabled
          />
        </div>
        <div className="form-row">
          <label htmlFor="comuna" className="form-label label--icon">
            Colectiva <small className="required">*</small>
          </label>
          <input
            type="text"
            className="form-control"
            id="region"
            placeholder="Tu colectiva"
            value={admin.colectiva?.name || 'N/A'}
            disabled
          />
        </div>
        <h2 className="text-24">Antecedentes y Conocimientos</h2>
        {(admin.questions || []).map((question) => (
          <div className="form-row" key={question.id}>
            <label
              htmlFor={`${question.id}`}
              className="form-label label--icon"
            >
              {question.name} <small className="required">*</small>
            </label>
            <input
              id={`${question.id}`}
              type="text"
              className="form-control"
              value={question.value || ''}
              disabled
            />
          </div>
        ))}
      </form>
    </div>
  );
};

export default SolicitudDetailComponent;
