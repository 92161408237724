import React, { useState } from 'react';
import HeaderShared from '../../../../shared/header.shared';
import FooterShared from '../../../../shared/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import TagsManagerComponent from '../../../../components/Forms/tagsManager/tagsManager.component';
import NotesComponent from '../../../../components/Elements/Notes/notes.component';
import TagsComponent from '../../../../components/Forms/tags/tags.component';
import { TAGS_DATA } from '../../../../utils/tags';
import PersonalDataComponent from '../../../../components/Forms/userDetail/personalData.component';
import DemographicDataComponent from '../../../../components/Forms/userDetail/demographicData.component';
import HealthDataComponent from '../../../../components/Forms/userDetail/healthData.component';
import InfoDataComponent from '../../../../components/Forms/userDetail/infoData.component';
import ModalDerivarComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivar.component';
import ModalDerivarOrgComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOrg.component';
import ModalDerivarOrgEndComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOrgEnd.component';
import ModalDerivarOirsComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOirs.component';
import ModalDerivarOirsEndComponents from '../../../../components/Elements/Modal/ModalDerivar/modalDerivarOirsEnd.component';
import ModalProcessComponents from '../../../../components/Elements/Modal/ModalProcess/modalProcess.component';
import ModalDerivarGrupoComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarGrupo.component';
import ModalDerivarGrupoEndComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarGrupoEnd.component';
import ModalDerivarProfesionalComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarProfesional.component';
import ModalDerivarProfesionalEndComponents from '../../../../components/Elements/Modal/ModalDerivarAdmin/modalDerivarProfesionalEnd.component';
import ActionsButtonComponent from '../../../../components/Elements/Buttons/actions.component';

const DetailUserScreen: React.FC = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowOrg, setModalShowOrg] = React.useState(false);
  const [modalShowOrgEnd, setModalShowOrgEnd] = React.useState(false);
  const [modalShowOirs, setModalShowOirs] = React.useState(false);
  const [modalShowOirsEnd, setModalShowOirsEnd] = React.useState(false);
  const [modalShowProcess, setModalShowProcess] = React.useState(false);
  const [modalShowOrgStatus, setModalShowOrgStatus] = React.useState(false);
  const [modalShowOrgStatusEnd, setModalShowOrgStatusEnd] =
    React.useState(false);
  const [modalShowProfessionalStatus, setModalShowProfessionalStatus] =
    React.useState(false);
  const [modalShowProfessionalStatusEnd, setModalShowProfessionalStatusEnd] =
    React.useState(false);
  const [modalType] = useState('');
  const tagsShow = [
    'tag-1',
    'tag-2',
    'tag-3',
    'tag-4',
    'tag-5',
    'tag-6',
    'tag-7',
    'tag-8',
    'tag-9',
    'tag-10',
    'tag-11',
    'tag-12',
  ]; // Definir los id de las tags que se quieren mostrar

  const tagsFiltered = TAGS_DATA.filter((tag) => tagsShow.includes(tag.id));

  return (
    <>
      <ModalDerivarComponents
        show={modalShow}
        onHide={() => setModalShow(false)}
        onNext={() => {
          setModalShow(false);
          setModalShowOrg(true);
        }}
        onNextTwo={() => {
          setModalShow(false);
          setModalShowOirs(true);
        }}
      />
      <ModalDerivarOrgComponents
        show={modalShowOrg}
        onHide={() => setModalShowOrg(false)}
        onEnd={() => {
          setModalShowOrg(false);
          setModalShowOrgEnd(true);
        }}
      />
      <ModalDerivarOrgEndComponents
        show={modalShowOrgEnd}
        onHide={() => setModalShowOrgEnd(false)}
      />
      <ModalDerivarOirsComponents
        show={modalShowOirs}
        onHide={() => setModalShowOirs(false)}
        onEnd={() => {
          setModalShowOirs(false);
          setModalShowOirsEnd(true);
        }}
      />
      <ModalDerivarOirsEndComponents
        show={modalShowOirsEnd}
        onHide={() => setModalShowOirsEnd(false)}
      />
      <ModalProcessComponents
        show={modalShowProcess}
        onHide={() => setModalShowProcess(false)}
      />
      <ModalDerivarGrupoComponents
        show={modalShowOrgStatus}
        onHide={() => setModalShowOrgStatus(false)}
        onEnd={() => {
          setModalShowOrgStatus(false);
          setModalShowOrgStatusEnd(true);
        }}
      />
      <ModalDerivarGrupoEndComponents
        show={modalShowOrgStatusEnd}
        onHide={() => setModalShowOrgStatusEnd(false)}
      />

      <ModalDerivarProfesionalComponents
        show={modalShowProfessionalStatus}
        onHide={() => setModalShowProfessionalStatus(false)}
        onEnd={() => {
          setModalShowProfessionalStatus(false);
          setModalShowProfessionalStatusEnd(true);
        }}
      />
      <ModalDerivarProfesionalEndComponents
        show={modalShowProfessionalStatusEnd}
        onHide={() => setModalShowProfessionalStatusEnd(false)}
      />
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="view-head">
              <div className="row">
                <div className="col-12 mb-3">
                  <a href="/inicio-acompanante" className="btn--back">
                    <i className="icon icon--back"></i> Regresar
                  </a>
                </div>
                <div className="view-head__data col-12">
                  <div className="row">
                    <div className="view-detail col-md-8 col-12">
                      <h1 className="text-40">Paulina Pimentel</h1>
                      <p className="text-20">
                        <strong>No. 273923</strong>
                      </p>
                      <div className="view-detail__tags">
                        <TagsComponent tags={tagsFiltered} />
                      </div>
                    </div>
                    <aside className="view-detail__action col-md-4 col-12">
                      <div className="view-detail__action-col col-12 col-reset">
                        <button
                          type="button"
                          className="btn btn--type2"
                          onClick={() => setModalShowProcess(true)}
                        >
                          Ver proceso
                        </button>
                        <button
                          type="button"
                          className="btn btn--simple-no-underline"
                          onClick={() => setModalShow(true)}
                        >
                          Derivar perfil
                        </button>
                        {modalType === 'grupo' ? (
                          <button
                            type="button"
                            className="btn btn--simple-no-underline"
                            onClick={() => setModalShowOrgStatus(true)}
                          >
                            Revisar solicitud de derivación
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn--simple-no-underline"
                            onClick={() => setModalShowProfessionalStatus(true)}
                          >
                            Revisar solicitud de derivación
                          </button>
                        )}
                        <ActionsButtonComponent />
                        <p className="btn text-wine400">
                          Este perfil fue transferido a Las Amigas
                        </p>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-body module-top40">
              <div className="row">
                <div className="view-body__info col-md-9 col-12">
                  <table className="table-user-data">
                    <tbody>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--message-clip"></i>{' '}
                            Registrada:
                          </p>
                        </td>
                        <td>18/01/2023 (24 horas)</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--user-clip"></i> Edad:
                          </p>
                        </td>
                        <td>23 años</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--user-clip"></i>{' '}
                            Acompañante:
                          </p>
                        </td>
                        <td>Rafaela</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--calendar-clip"></i>{' '}
                            Semanas:
                          </p>
                        </td>
                        <td>6 + 3 días</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Derivada
                            de:
                          </p>
                        </td>
                        <td>OLA</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Causal:
                          </p>
                        </td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td>
                          <p className="data-info">
                            <i className="icon icon--edit-clip"></i> Comuna:
                          </p>
                        </td>
                        <td>Valparaíso</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-12 mt-3">
                    <a href="/bitacora" className="btn btn--type1">
                      Bitácora de la mujer
                    </a>
                  </div>
                  <div className="col-12 mt-3">
                    <Accordion
                      defaultActiveKey="0"
                      className="accordion-regular"
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Etiquetas</Accordion.Header>
                        <Accordion.Body className="pl-0 pr-0">
                          <TagsManagerComponent
                            admin={{}}
                            user={{}}
                            showLabel={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Notas</Accordion.Header>
                        <Accordion.Body>
                          <NotesComponent admin={{}} user={{}} />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Información personal
                        </Accordion.Header>
                        <Accordion.Body>
                          <PersonalDataComponent />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>
                          Datos Sociodemográficos
                        </Accordion.Header>
                        <Accordion.Body>
                          <DemographicDataComponent />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>Salud y Cuidados</Accordion.Header>
                        <Accordion.Body>
                          <HealthDataComponent />
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header>Contexto</Accordion.Header>
                        <Accordion.Body>
                          <InfoDataComponent />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
                <aside className="view-body__aside col-md-3 col-12">
                  <a href="/" className="btn btn--chat">
                    Ver Chat <i className="icon icon--chat"></i>
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default DetailUserScreen;
