import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import FooterShared from '../../../shared/footer.shared';
import UserCardComponent from '../../../components/Elements/UserCard/userCard.component';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import {
  AdminPrincipalRol,
  AdminSteps,
  IAdmin,
} from '../../../types/adminInterface';
import { getSession } from '../../../utils/helpers';
import { searchAdmins } from '../../../api/adminApi';
import LoaderComponent from '../../../components/Loader/loader.component';
import { format } from 'date-fns';

const SolicitudesScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState<IAdmin[]>([]);

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchAdmins = async () => {
    try {
      setLoading(true);

      const session = getSession();
      if (!session || !session.token) {
        navigate('/iniciar-sesion');
        return;
      }

      const result = await searchAdmins(
        1,
        50,
        {
          principalRol: AdminPrincipalRol.ACOMPANANTE,
          stepLt: AdminSteps.GET_OLA_ACCOUNT,
        },
        session.token
      );

      setAdmins(result.docs);
      setLoading(false);
    } catch (error) {
      setError('Error al obtener los administradores');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <>
      <HeaderAdminShared />
      <section className="module40">
        {loading && <LoaderComponent />}
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/inicio-acompanante" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              {admins.length === 0 && (
                <>
                  <div className="col-12 d-flex justify-content-center">
                    <figure className="image-179">
                      <img
                        src="/assets/images/illustration/404.png"
                        alt="No tienes nuevos acompañamientos asignados"
                      />
                    </figure>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <article>
                      <h1 className="h3 text-regular">
                        No tienes nuevos solicitudes
                      </h1>
                      <p>Pronto llegarán nuevas peticiones.</p>
                      <a href="/inicio-acompanante" className="btn btn--type1">
                        Ir al inicio
                      </a>
                    </article>
                  </div>
                </>
              )}
              {admins.length > 0 && (
                <div className="col-12 col-md-8 mx-auto mt-3">
                  <article className="text-center mb-4">
                    <h1 className="h2 ">
                      {admins.length} nuevas solicitudes de gestoras
                    </h1>
                  </article>
                  <div className="row justify-content-center">
                    {admins.map(
                      ({ username, adminId, createdAt, phone, _id }, index) => (
                        <div key={index} className="col-md-6 col-12 mb-4">
                          <UserCardComponent
                            name={username}
                            id={`${adminId}`}
                            date={format(
                              new Date(createdAt || ''),
                              'dd MMMM yyyy'
                            )}
                            phone={phone}
                            detailUrl={`/detalle-solicitud/${_id}`}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default SolicitudesScreen;
