import React from 'react';
import { Modal} from 'react-bootstrap';

interface ModalAsignarSuccessComponentProps {
  show: boolean;
  onHide: () => void;
}

const ModalAsignarSuccessComponent: React.FC<ModalAsignarSuccessComponentProps> = ({ show, onHide }) => {
  
 

  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      
      <Modal.Body>
          <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img src="/assets/images/icons/checked.svg" alt="Success" />
            </figure>
          </div>
          <h2 className="text-40 text-center mb-4">Se asignó acompañante con éxito</h2>
          <p className="text-center text-24 mb-0">Has asignado como <strong>gestora</strong> a <strong>Eugenia</strong> 
          para acompañar a esta mujer</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="button" className="btn btn--type1 btn--290" onClick={onHide}>Regresar al perfil</button>
        </div>
       
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalAsignarSuccessComponent;