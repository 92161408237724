import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import LoaderComponent from '../../Loader/loader.component';

interface OlaInfoComponentProps {
  admin: IAdmin;
}

const GestoraStep2Component: React.FC<OlaInfoComponentProps> = ({
  admin: prevAdmin,
}) => {
  const [formularioValido, setFormularioValido] = useState(false);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [loading, setLoading] = useState(true);
  const [countryList, setCountryList] = useState<strapiDoc[]>([]);
  const [stateList, setStateList] = useState<strapiDoc[]>([]);
  const [cityList, setCityList] = useState<strapiDoc[]>([]);
  const [ageRangeList, setAgeRangeList] = useState<strapiDoc[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Inicializa el estado con prevAdmin
    if (prevAdmin) {
      setAdmin(prevAdmin);
    }
  }, [prevAdmin]);

  const fetchStates = async (countryId: number) => {
    // Fetch states
    const query = {
      sort: ['nombre:asc'],
      filters: {
        pais: { $eq: countryId },
      },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const states = await fetchStrapiDocs('/estados', query);
    setStateList(states.data);
  };

  const onFieldChange = (
    name: keyof IAdmin,
    value: string | { id: number; name: string }
  ) => {
    setAdmin((prevState) => ({ ...prevState, [name]: value }));
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        todosCompletos;
      } else {
        todosCompletos = todosCompletos && campo.value.trim() !== '';
      }
    });

    setFormularioValido(todosCompletos);
  };

  const fetchCities = async (stateId: number) => {
    // Fetch cities
    const query = {
      sort: ['nombre:asc'],
      filters: { estado: { $eq: stateId } },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const cities = await fetchStrapiDocs('/ciudades', query);
    setCityList(cities.data);
  };

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCityList([]);
    const stateId = Number(e.target.value);
    const foundedState = stateList.find(({ id }) => id === stateId);
    if (foundedState) {
      const {
        id,
        attributes: { nombre, colectivas },
      } = foundedState;
      setAdmin({
        ...admin,
        state: { id, name: nombre },
        colectiva: colectivas?.data
          ? {
              id: colectivas.data[0].id,
              name: colectivas.data[0].attributes.nombre,
            }
          : undefined,
      });
      fetchCities(stateId);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const stateAdmin = {
      ...admin,
    };
    navigate('/gestora-paso-3', { state: { admin: stateAdmin } });
  };

  useEffect(() => {
    verifyFields();
  }, [admin]);

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityId = Number(e.target.value);
    const foundedCity = cityList.find(({ id }) => id === cityId);
    if (foundedCity) {
      const {
        id,
        attributes: { nombre },
      } = foundedCity;
      setAdmin({
        ...admin,
        city: { id, name: nombre },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { sort: ['nombre:asc'] };
        const countries = await fetchStrapiDocs('/paises', query);
        const ageRanges = await fetchStrapiDocs('/rangos-de-edad', {
          sort: ['order:asc'],
        });
        const data = countries.data;
        data.push({ id: 0, attributes: { nombre: 'Otro' } });
        setCountryList(countries.data);

        if (ageRanges && ageRanges.data) {
          setAgeRangeList(ageRanges.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        if (prevAdmin && prevAdmin.username) {
          setAdmin(prevAdmin);
          if (prevAdmin.birthday) {
            setAdmin({
              ...prevAdmin,
            });
          }
          if (prevAdmin.country?.id) {
            fetchStates(prevAdmin.country.id || 0);
          }
          if (prevAdmin.state?.id) {
            fetchCities(prevAdmin.state.id);
          }
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="age" className="form-label label--icon">
              Rango de edad <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Selecciona tu rango de edad</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select requiredField"
              id="age"
              onChange={(e) => onFieldChange('birthday', e.target.value)}
              value={admin.birthday || ''}
            >
              <option value="">Selecciona tu rango de edad</option>
              {ageRangeList.map(({ id, attributes }) => (
                <option key={id} value={attributes.nombre}>
                  {attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label htmlFor="nation" className="form-label label--icon">
              Nacionalidad <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Selecciona tu nacionalidad</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select requiredField"
              value={admin.country?.id || 'default'}
              onChange={(e) => {
                const countryId = Number(e.target.value);
                const foundedCountry = countryList.find(
                  ({ id }) => id === countryId
                );
                if (!foundedCountry) return;
                const value = {
                  id: foundedCountry.id,
                  name: foundedCountry.attributes.nombre,
                };
                onFieldChange('country', value);
                fetchStates(countryId);
              }}
            >
              <option value="">Elige una de las opciones</option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          {stateList.length > 0 && (
            <div className="form-row">
              <label htmlFor="region" className="form-label label--icon">
                Región <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Selecciona tu región</Tooltip>}
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                value={admin.state?.id || 'default'}
                onChange={handleStateChange}
              >
                <option value="">Elige una de las opciones</option>
                {stateList.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.attributes.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}
          {cityList.length > 0 && (
            <div className="form-row">
              <label htmlFor="comuna" className="form-label label--icon">
                Comuna <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Selecciona tu comuna</Tooltip>}
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                onChange={handleCityChange}
                defaultValue={'default'}
                value={admin.city?.id || 'default'}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {cityList.map((city, index) => (
                  <option key={index} value={city.id}>
                    {city.attributes.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Siguiente
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GestoraStep2Component;
