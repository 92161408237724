export const NIVEL_DATA = [
  {
  "title": "Básica",
      "levels": [
          {"id": 1, "label": "Educación Básica en curso"},
          {"id": 2, "label": "Educación Básica completa"},
          {"id": 3, "label": "Educación Básica incompleta"}
      ]
  },
  {
  "title": "Media",
      "levels": [
          {"id": 4, "label": "Educación Media en curso"},
          {"id": 5, "label": "Educación Media completa"},
          {"id": 6, "label": "Educación Media incompleta"}
      ]
  },
  {
      "title": "Superior",
      "levels": [
        {"id": 1, "label": "Técnico Superior en curso"},
        {"id": 2, "label": "Técnico Superior completa"},
        {"id": 3, "label": "Técnico Superior incompleta"}
      ]
    },
    {
      "title": "Universitaria",
      "levels": [
        {"id": 4, "label": "Universitario en curso"},
        {"id": 5, "label": "Universitaria completa"},
        {"id": 6, "label": "Universitaria incompleta"}
      ]
    }
]
