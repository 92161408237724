import React from 'react';
import { format } from 'date-fns';
import GestoraItemDateComponent from '../Buttons/gestoraItem.component';
import { IAdmin } from '../../../types/adminInterface';

interface Props {
  admins: IAdmin[];
}

const GestoraListDateComponent: React.FC<Props> = ({ admins }) => {
  return (
    <div className="talleres-list">
      <ul>
        {admins.slice(0, 3).map((admin, index) => (
          <li key={index}>
            <GestoraItemDateComponent
              title={admin.username || ''}
              organizer={admin.colectiva?.name || ''}
              date={format(new Date(admin.createdAt || ''), 'dd MMM yyyy')}
              url={`/detalle-solicitud/${admin._id}`}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GestoraListDateComponent;
