import axios from 'axios';
import qs from 'qs';
import { strapiDoc, strapiDocPaginate } from '../types/strapiDocs';

const strapi = axios.create({
  baseURL: 'https://olacms.manifesto.design/api/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer 7b553adb5f71ca4f48bc9e100cd2e56cf548aa0fe2e3ad5e7b8e2567f38da0eeef19400765914a605c64b183d00bbf0ce6633ab6f2157f8b2ca2953853e5d6676e99e5f376f9255ddc607b12d601e4fb23b41ea960396a61a58432f02210b0c20b42769c6e5d67aca6f0b9e772c1097679844196a0890a84e1be7e72a26bd7a6`,
  },
});

const fetchStrapiDocs = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<strapiDocPaginate>(`${route}?${query}`);
  return response.data;
};

const fetchStrapiSingle = async (route: string, params: object) => {
  const query = qs.stringify(params);
  const response = await strapi.get<{
    data: strapiDoc;
  }>(`${route}?${query}`);
  return response.data;
};

export { fetchStrapiDocs, fetchStrapiSingle };
