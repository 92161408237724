import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface HealthDataProps {
}

const HealthDataComponent: React.FC<HealthDataProps> = () => {

  return (
    <div className="form-block">
       <form >
        <div className="form-row">
          <label htmlFor="weeks" className="form-label label--icon">
            Semanas de embarazo al día de hoy
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Semanas de embarazo del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="weeks"
            placeholder="Semanas de embarazo"
            value={'6 semanas y 3 días'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="trimestre" className="form-label label--icon">
           Trimestre de embarazo
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Trimestre de embarazo del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="trimestre"
            placeholder="Trimestre de embarazo"
            value={'Primer'}
            
          />
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col-6">
                <label htmlFor="metodo" className="form-label label--icon">
                  Método para comprobar
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip >
                          Método para comprobar de embarazo del usuario
                        </Tooltip>
                      }
                    >
                      <span className="material-icons-outlined icon">help_outline</span>
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metodo"
                    placeholder="Método para comprobar embarazo"
                    value={'Test'}
                    
              />
            </div>
            <div className="col-6">
               <label htmlFor="metodo" className="form-label label--icon">
                  Usaba anticonceptivos
                    <OverlayTrigger
                      placement='bottom'
                      overlay={
                        <Tooltip >
                          Uso de anticonceptivos del usuario
                        </Tooltip>
                      }
                    >
                      <span className="material-icons-outlined icon">help_outline</span>
                    </OverlayTrigger>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="metodo"
                    placeholder="Uso de anticonceptivos"
                    value={'Sí'}
                    
              />
            </div>
          </div>
        
        </div>
        <div className="form-row">
          <label htmlFor="metodo" className="form-label text-20">Ecografía</label>
          <div className="image-user">
            <figure>
              <img src="/assets/images/user-data/ecografia.jpg" alt='Ecografía' />
            </figure>
            <div className="image-user__data">
              <i className="icon icon--image"></i>
              <article>
                <p className="text-18 pb-2"><strong>Ecografía</strong></p>
                <p className="text-18">Subida correctamente</p>
                <p className="text-18">17/01/2023</p>
                <p className="text-18">11:34 AM</p>
              </article>
            </div>
          </div>
        </div>
        <div className="form-row">
          <label htmlFor="gestaciones" className="form-label label--icon">
            Gestaciones anteriores
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Gestaciones anteriores del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="gestaciones"
            placeholder="Gestaciones anteriores"
            value={'Ninguna'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="intento-aborto" className="form-label label--icon">
            Intentó abortar en este embarazo
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Intentó abortar en este embarazo del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="intento-aborto"
            placeholder="Intentó abortar en este embarazo"
            value={'No'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="antecedente" className="form-label label--icon">
            Antecedente de salud relevante
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Antecedente de salud relevante del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="antecedente"
            placeholder="Antecedente de salud relevante"
            value={'Ninguna'}
            
          />
        </div>
        <div className="form-row">
          <div className="row">
            <div className="col-6">
                <label htmlFor="acompanante" className="form-label label--icon">
                Acompañante
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Acompañante del usuario
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
              <input
                type="text"
                className="form-control"
                id="acompanante"
                placeholder="Acompañante"
                value={'Sí'}
                
              />
            </div>
            <div className="col-6">
                <label htmlFor="acompanante-name" className="form-label label--icon">
                  ¿Quien?
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip >
                        Nombre de la acompañante del usuario
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="acompanante-name"
                  placeholder="Nombre de la acompañante"
                  value={'Amiga, Prima'}
                  
                />
            </div>
          </div>
        </div>
        <div className="form-row">
          <label htmlFor="relacion" className="form-label label--icon">
            Actualmente en relación heterosexual
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Relación heterosexual del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="relacion"
            placeholder="Actualmente en relación heterosexual"
            value={'Sí'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="relacion" className="form-label label--icon">
             Considera que está en relación violenta o de peligro
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Relación violenta o de peligro del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="relacion"
            placeholder="Considera que está en relación violenta o de peligro"
            value={'No'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="consentimiento" className="form-label label--icon">
          Consentimiento en relación de embarazo
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Consentimiento del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="consentimiento"
            placeholder="Consentimiento en relación de embarazo"
            value={'Sí'}
            
          />
        </div>
      </form>
    </div>
  );
};

export default HealthDataComponent;