import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';

interface ModalDeleteComponentProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalDeleteComponent: React.FC<ModalDeleteComponentProps> = ({ show, onHide, onSuccess }) => {
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] = useState(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    
    // Simulando el envío exitoso del formulario
    // Aquí debes agregar la lógica real para enviar el formulario al servidor
    // y establecer setFormSubmittedSuccessfully(true) en la devolución de llamada de éxito
    setFormSubmittedSuccessfully(true);
    onSuccess();
  };

  const handleModalClose = () => {
    onHide();

    // Si el formulario se envió con éxito, reiniciar el estado de formSubmittedSuccessfully
    if (formSubmittedSuccessfully) {
      setFormSubmittedSuccessfully(false);
    }
  };


  return (
    <>
     <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center">
            <i className="icon icon--warning"></i>
          </div>
         
          <div className="col-12 text-center">
            <h2 className="text-center">Eliminar cuenta</h2>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-24 text-center mb-5 col-lg-7 col-12 mx-auto">Toda la información desaparecerá 
        de la base de datos de “OLA”</p>
        <form onSubmit={handleSubmit}>
          
          <div className="form-row d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn--delete btn--290"
            >Eliminar cuenta</button>
          </div>
          <div className="form-row d-flex justify-content-center">
            <button
              type="button"
              className="btn text-purple400 btn--290"
              onClick={handleModalClose}
            >Cancelar</button>
          </div>
        </form>
      </Modal.Body>
      
    </Modal>
    </>
  );
};

export default  ModalDeleteComponent;