import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import Step6Component from '../../../components/Auth/Gestora/gestoraStep6.component';
import { IAdmin } from '../../../types/adminInterface';
import { getSession } from '../../../utils/helpers';

interface Props {
  // Define your component props here
}

const steps = [
  {
    title: 'Recibimos tu perfil',
    description: 'En proceso',
    status: 'status--process',
    completed: false,
    color: 'pink400',
  },
  {
    title: 'Agendamos una entrevista',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Conoce al equipo OLA',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Recibe tu acceso a OLA',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Empieza a hacer la diferencia',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },

  // Status color
  //-- In progress status--process - green350
  //-- In progress status--success - pink400
  //-- In progress status neutral '' - gray600
];

const GestoraComunitariaProcessScreen: React.FC<Props> = () => {
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const { id } = useParams();

  const fetchAdmin = () => {
    const session = getSession();
    if (session) {
      setAdmin(session);
      return;
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-md-8 col-12">
                <h1 className="text-64 mb-3">
                  ¡{`${admin.username || ''}`}, gracias por registrarte como
                  gestora!
                </h1>
                <p className="text-32 mb-0">
                  <strong>
                    Revisaremos cuidadosamente tu solicitud y nos pondremos en
                    contacto contigo
                  </strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              {admin.email !== '' && admin.password !== '' && id ? (
                <Step6Component admin={admin} />
              ) : (
                <div className="col-12">
                  <div className="alert alert--simple-check col-md-6 col-12">
                    <span className="icon material-icons-outlined">
                      check_circle
                    </span>
                    <span className="text-20">
                      <strong>
                        Revisaremos a detalle tu solicitud para ser gestora.
                        Mantente pendiente de tu correo.
                      </strong>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestoraComunitariaProcessScreen;
