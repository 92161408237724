import React, { useEffect, useState } from 'react';
import FooterShared from '../../../shared/footer.shared';
import WomenComponent from '../../../components/Elements/Women/women.component';
import GestoraListDateComponent from '../../../components/Elements/Gestora/gestoraList.component';
import Swal from 'sweetalert2';
import { deleteSession, getSession } from '../../../utils/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { getAdminDashboard } from '../../../api/adminApi';
import { IUserPaginate } from '../../../types/userInterface';
import { IAdmin } from '../../../types/adminInterface';
import LoaderComponent from '../../../components/Loader/loader.component';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';

const AdminScreen: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUserPaginate>();
  const [gestoras, setGestoras] = useState<IAdmin[]>([]);

  const navigate = useNavigate();

  const setError = (error: string) => {
    console.log(error);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
    });
  };

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  const fetchDashboard = async () => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session || !session.token) {
        return navigate('/iniciar-sesion');
      }
      const response = await getAdminDashboard(session.token);
      console.log(response, 'Response');
      if (response) {
        setUsers(response.totalUsers);
        setGestoras(response.adminRequests.docs);
      }
    } catch (error) {
      setError('Error al cargar los datos');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboard();
  }, []);

  return (
    <section className="dashboard-index">
      <HeaderAdminShared />
      {/* <HeaderAdminSearch /> */}
      {loading && <LoaderComponent />}
      <section className="dashboard-index__container container">
        <div className="row">
          <div className="dashboard-index__desktop col-xl-3 col-12">
            <section className="dashboard-card dashboard--1 dashboard--v100">
              <div className="dashboard-card__inner">
                <div className="dashboard-card__inner-content">
                  <div className="d-flex justify-content-center">
                    <figure className="logo-init">
                      <img src="/assets/images/logo/ola-white.png" alt="OLA" />
                    </figure>
                  </div>
                  <div className="dashboard-card__buttons">
                    <ul>
                      {/* <li>
                        <Link to="/colectivas" className="btn btn--type5">
                          <i className="icon icon--colectivas"></i>
                          Colectivas
                        </Link>
                      </li> */}
                      <li>
                        <Link to="/equipo-ola" className="btn btn--type5">
                          <i className="icon icon--equipo"></i>
                          Equipo OLA
                        </Link>
                      </li>
                      <li>
                        <Link to="/mujeres" className="btn btn--type5">
                          <i className="icon icon--mujeres"></i>
                          Mujeres
                        </Link>
                      </li>
                      <li>
                        <Link to="/solicitudes" className="btn btn--type5">
                          <i className="icon icon--mujeres"></i>
                          Solicitudes
                        </Link>
                      </li>
                      <li>
                        <Link to="/documentos" className="btn btn--type5">
                          <i className="icon icon--donativos"></i>
                          Documentos
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <a
                  href="#"
                  className="btn btn--logout"
                  onClick={(event) => handleLogOut(event)}
                >
                  <i className="icon icon--log-close"></i> Cerrar sesión
                </a>
              </div>
            </section>
          </div>
          <div className="dashboard-index__middle col-xl-5 col-12">
            {/* <section className="dashboard-card dashboard--3 dashboard--donate mb-4">
              <h2 className="h4">Documentos</h2>
              <DonationsInitComponent
                number={50100.5}
                date="20 de septiembre 2021"
                size="large"
                clp={true}
              />
              <div className="donations-list">
                <p className="text-500">Últimos documentos recibidos </p>
                <DonationsListComponent numbers={3} />
                <a href="/documentos" className="btn btn--type1 btn--100">
                  Ver detalle de documentos
                </a>
              </div>
            </section> */}
            <section className="dashboard-card dashboard--2">
              {users && <WomenComponent users={users} />}
              <Link to="/mujeres" className="btn btn--type2 btn--100">
                Ver todas las mujeres
              </Link>
            </section>
          </div>
          <div className="col-xl-4 col-12">
            <section className="dashboard-card dashboard--4 mb-4">
              <h2 className="h4">Nuevas Solicitudes de Gestoras</h2>
              <GestoraListDateComponent admins={gestoras} />
              <Link to="/solicitudes" className="btn btn--type1 btn--100">
                Ver todas las solicitudes
              </Link>
            </section>
          </div>
        </div>
      </section>
      <FooterShared />
    </section>
  );
};

export default AdminScreen;
