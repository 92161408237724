import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import { getSession } from '../../../utils/helpers';
import { getUser } from '../../../api/usersApi';
import { IUser, UserStatus } from '../../../types/userInterface';
import LoaderComponent from '../../../components/Loader/loader.component';
import FooterShared from '../../../shared/footer.shared';

const EditUserSuccessScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({});

  const { id } = useParams<{ id: string }>();

  const setError = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchUser = async () => {
    try {
      setLoading(true);
      if (!id) {
        throw new Error('No se pudo obtener el id del usuario');
      }
      const session = getSession();
      const response = await getUser(id, session?.token || '');

      if (response.status === UserStatus.BLOCKED) {
        navigate('/');
      }
      setUser(response);
    } catch (error) {
      setError('Error al obtener la información del usuario');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchUser();
  }, []);

  return (
    <>
      {loading && <LoaderComponent />}
      <HeaderAdminShared />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="mx-auto text-center col-lg-10 col-12">
                <div className="d-flex justify-content-center">
                  <figure className="image-270">
                    <img
                      src="/assets/images/illustration/book.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                <h1 className="mb-3 text-40">
                  ¡Se actualizaron los datos con éxito!
                </h1>
                <p>
                  La información de <strong>{`${user.username}`}</strong> fue
                  actualizada con éxito y todas podrán ver los cambios en las su
                  ficha
                </p>
              </div>
            </div>
            <div className="form-row form--last">
              <a
                href={`/detalle-usuario/${user._id || ''}`}
                className="btn btn--type1 btn--270"
              >
                Regresar a ficha
              </a>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EditUserSuccessScreen;
