import React, {useState} from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {ACOMPANANTES_DATA} from '../../../../utils/acompanantes'
import {GESTORAS_DATA} from '../../../../utils/gestoras'

interface ModalAsignarComponentProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalAsignarComponent: React.FC<ModalAsignarComponentProps> = ({ show, onHide, onSuccess }) => {
  const [selectedOption, setSelectedOption] = useState<string>('default');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const isButtonDisabled = selectedOption === 'default';

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    
    // Simulando el envío exitoso del formulario
    // Aquí debes agregar la lógica real para enviar el formulario al servidor
    // y establecer setFormSubmittedSuccessfully(true) en la devolución de llamada de éxito
    onSuccess();
  };

  const handleModalClose = () => {
    onHide();

   
  };

 

  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" >
          <div className="col-12 d-flex justify-content-center">
            <i className="icon icon--user-add"></i>
          </div>
         
          <div className="col-12">
            <h2 className="text-center"> Asignar profesional</h2>
          </div>
        </Modal.Title>
   
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p className="text-24 text-center mb-5">Selecciona una acompañante de la Colectiva</p>
        <form onSubmit={handleSubmit}>
          
          <div className="form-row pb-3">
            
            <label className="form-label label--icon">
            Selecciona el rol de profesional a asignar  <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Selecciona el rol de profesional a asignar 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
                <select
                  className="form-select requiredField"
                  aria-label="Acompañante"
                  defaultValue={'default'}
                  name="acompanante"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="default" disabled>Selecciona una opción</option>
                  {ACOMPANANTES_DATA.map((acompanante) =>
                    <option key={acompanante.id} value={acompanante.text}>
                      {acompanante.text}
                    </option>
                  )}
                </select>
          </div>
          <div className="form-row pb-3">
            
            <label className="form-label label--icon">
            Selecciona una de las gestoras  <small className="required">*</small>
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip >
                      Selecciona una de las gestoras 
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">help_outline</span>
                </OverlayTrigger>
              </label>
                <select
                  className="form-select requiredField"
                  aria-label="Acompañante"
                  defaultValue={'default'}
                  name="acompanante"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="default" disabled>Selecciona una opción</option>
                  {GESTORAS_DATA.map((gestora) =>
                    <option key={gestora.id} value={gestora.text}>
                      {gestora.text}
                    </option>
                  )}
                </select>
          </div>
          <div className="form-row d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn--type1 btn--290"
              disabled={isButtonDisabled}
            >Asignar</button>
          </div>
          <div className="form-row d-flex justify-content-center">
            <button
              type="button"
              className="btn btn--type2 btn--290"
              onClick={handleModalClose}
            >Cancelar</button>
          </div>
        </form>
      </Modal.Body>
      
    </Modal>
    </>
  );
};

export default  ModalAsignarComponent;