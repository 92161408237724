import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  // Define your component props here
}

const CapacitacionComponent: React.FC<Props> = () => {
  const navigate = useNavigate();
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/detalle-usuario');
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit}>
      <div className="form-row">
        <div className="form-check check--flex mb-5">
          <input
            className="form-check-input"
            type="checkbox"
            name="capacitacion"
            id="derechos"
            value="derechos"
          />
          <label className="form-check-label text-500" htmlFor="derechos">
            Enfoque de derechos 
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="form-check check--flex mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="capacitacion"
            id="legal"
            value="legal"
          />
          <label className="form-check-label text-500" htmlFor="legal">
            Estructura legal
          </label>
        </div>
        <ul className="mb-4 pl-20">
          <li>Ley 21.030 Abortos en 3 Causales</li>
          <li>Ley 21.371 Duelo Perinatal </li>
          <li>Ley 20.584 los Derechos y Deberes de los/as Pacientes</li>
          <li>Ley 21.430 Garantía y Protección Integral de los Derechos de la Niñez y Adolescencia
          </li>
        </ul>
      </div>
      <div className="form-row">
        <div className="form-check check--flex mb-5">
          <input
            className="form-check-input"
            type="checkbox"
            name="capacitacion"
            id="estructura"
            value="estructura"
          />
          <label className="form-check-label text-500" htmlFor="estructura">
            Estructura Sanitaria en Chile y Funcionamiento de los Servicios de Salud, Incluye Reclamo OIRS
          </label>
        </div>
       
      </div>
      <div className="form-row">
        <div className="form-check check--flex mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="capacitacion"
            id="crisis"
            value="crisis"
          />
          <label className="form-check-label text-500" htmlFor="crisis">
            Contención en crisis y registro de emociones
          </label>
        </div>
        <ul className="mb-5 pl-20">
          <li>Ir al servicio de urgencia</li>
          <li>Solicitar hablar con equipo IVE </li>
          <li>Entrevista equipo IVE</li>
          <li>Procedimiento</li>
        </ul>
      </div>
      <div className='form-row'>
        <button className="btn btn--type3 btn--100" type="submit">Guardar</button>
      </div>
    </form>
  );
};

export default CapacitacionComponent;