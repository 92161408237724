import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';
interface OlaInfoComponentProps {
  admin: Partial<IAdmin>;
}
const GestoraStep1Component: React.FC<OlaInfoComponentProps> = ({
  admin: prevAdmin,
}) => {
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [formularioValido, setFormularioValido] = useState(false);
  const navigate = useNavigate();

  const onFieldChange = (
    name: keyof IAdmin,
    value: string | { id: number; name: string } | boolean
  ) => {
    setAdmin({ ...admin, [name]: value });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
          console.log(campoCompleto);
        }
      }
    });

    gruposRadio.forEach((nombre) => {
      const seleccionado =
        document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;

      console.log(seleccionado);
    });

    setFormularioValido(todosCompletos);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const stateAdmin = { ...admin, ...prevAdmin };
    navigate('/gestora-paso-2', { state: { admin: stateAdmin } });
  };

  useEffect(() => {
    if (prevAdmin) {
      setAdmin({ ...prevAdmin });
    }
  }, [prevAdmin]);

  useEffect(() => {
    verifyFields();
    console.log(admin);
  }, [admin]);

  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="username" className="form-label label--icon">
              Nombre <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Nombre</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="¿Cómo te llamas?"
              value={admin.username || ''}
              onChange={(e) => {
                onFieldChange('username', e.target.value);
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="lastName" className="form-label label--icon">
              Apellido <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Apellido</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              placeholder="¿Cómo te llamas?"
              value={admin.lastName || ''}
              onChange={(e) => {
                onFieldChange('lastName', e.target.value);
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="email" className="form-label label--icon">
              Correo electrónico <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Esta información es importante para poder tener un medio de
                    contacto durante este procedimiento.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Introduce tu correo"
              value={admin.email || ''}
              onChange={(e) => {
                onFieldChange('email', e.target.value);
              }}
            />
          </div>
          <div className="form-row">
            <label htmlFor="phone" className="form-label label--icon">
              Número celular <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>Número celular</Tooltip>}
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              placeholder="Introduce tu número celular"
              value={admin.phone || ''}
              onChange={(e) => {
                onFieldChange('phone', e.target.value);
              }}
            />
          </div>
          <div className="form-row pb-4">
            <div className="form-check check--flex">
              <input
                className="form-check-input requiredField"
                type="checkbox"
                name="acepto-privacidad"
                id="privacidad"
                value="privacidad"
                checked={admin.acceptedPolicy || false}
                onChange={(e) => {
                  onFieldChange('acceptedPolicy', e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="privacidad">
                He leído y aceptado la <a href="/">Política de Privacidad</a>
              </label>
            </div>
          </div>
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Siguiente
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default GestoraStep1Component;
