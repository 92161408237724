import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ProcessStatusComponent from '../../ProcessStatus/processStatus.component';

interface ModalProcessProps {
  show: boolean;
  onHide: () => void;
}
const ModalProcessComponents: React.FC<ModalProcessProps> = ({ show, onHide }) => {
  const steps = [
    { title: "Cuéntanos de ti", description: "Recibimos tu información", status: "status--process", completed: false, color: "purple500" },
    { title: "Te presentamos a tu acompañante", description: "En proceso", status: "", completed: false, color: "gray600" },
    { title: "Te invitamos al taller", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Seguimos en contacto", description: "Pendiente", status: "", completed: false, color: "gray600" },
    { title: "Déjanos tu opinión", description: "Pendiente", status: "", completed: false, color: "gray600" },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

 
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          
          <h2 className="text-40 text-center text-500 pb-2">Historial de Paulina</h2>
          <ProcessStatusComponent steps={steps} currentStepIndex={0} /> 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h3 className="h4 text-regular">Detalle del progreso</h3>
          <div className="card-process-info">
            <h4 className="h6 text-regular text-pink500 mb-3">Hoy</h4>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Es momento de asignarle un taller</p>
              </div>
              <div className="card-process__right">
                <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Contactó a su acompañante</p>
              </div>
              <div className="card-process__right">
                <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
          </div>
          <div className="card-process-info">
            <h4 className="h6 text-regular text-pink500 mb-3">Ayer</h4>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Realizó un documento</p>
              </div>
              <div className="card-process__right">
              <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Se le asignó una profesional</p>
              </div>
              <div className="card-process__right">
                <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
          </div>
          <div className="card-process-info">
            <h4 className="h6 text-regular text-pink500 mb-3">21/Junio/2023</h4>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Contactó a su acompañante</p>
              </div>
              <div className="card-process__right">
              <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
            <div className="card-process__row">
              <div className="card-process__left">
                <p className="icon-text"><i className="icon icon--document"></i>Completó su registro</p>
              </div>
              <div className="card-process__right">
                <p className="text-gray600 text-flex-center">28/ JUN /23 <i className='icon--dot'></i> 12:00hrs</p>
              </div>
            </div>
          </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center">
          <p className="text-20"><strong>Ponte en contacto con la mujer para conocerla y coordinar juntas el acompañamiento</strong></p>
        </div>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="button" className="btn btn--type1 btn--370">Contactar</button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type2 btn--370" onClick={onHide}>Cerrar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalProcessComponents;
