import React from 'react';
import FooterShared from '../../../shared/footer.shared';
import Accordion from 'react-bootstrap/Accordion';
import ColectivaTableComponent from '../../../components/Elements/DataTable/colectivaTable.component';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';

const ColectivaDetailScreen: React.FC = () => {
  return (
    <>
      <HeaderAdminShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom row">
            <div className="col-12 mb-4">
              <a href="/colectivas" className="btn--back">
                <i className="icon icon--back"></i> Regresar
              </a>
            </div>
            <div className="title-section title--between module-bottom40 col-12">
              <h1 className="h2">Colectiva Quintero, Valparaíso</h1>
              <a href="#" className="btn btn--with-number">
                Aporte total $5,000 CLP{' '}
                <i className="icon icon--arrow-right-large"></i>
              </a>
            </div>
            <aside className="dashboard-aside col-md-3 col-12">
              <div className="dashboard-aside__inner">
                <h4 className="mb-4">Buscar mujer</h4>
                <div className="form-row form--search">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar por nombre, etiqueta, etc"
                  />
                  <i className="icon icon--search-bar"></i>
                </div>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Equipo OLA </Accordion.Header>
                    <Accordion.Body className="pl-0 pr-0">
                      <div className="form-row">
                        <h4 className="h5">Asesora Legal</h4>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Rita</strong>
                        </div>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Victoría</strong>
                        </div>
                      </div>
                      <div className="form-row">
                        <h4 className="h5">Acompañantes</h4>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Rafaela</strong>
                        </div>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Ana</strong>
                        </div>
                      </div>
                      <div className="form-row">
                        <h4 className="h5">Psicóloga</h4>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Rafaela</strong>
                        </div>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Ana</strong>
                        </div>
                      </div>
                      <div className="form-row">
                        <h4 className="h5">Gestoras Comunitarias</h4>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Rafaela</strong>
                        </div>
                        <div className="badge-neutral text-purple400 mb-2">
                          <strong>Ana</strong>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Chat interno</Accordion.Header>
                    <Accordion.Body>
                      <ul className="user-pic-selection">
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                        <li>
                          <button type="button">
                            <figure>
                              <img
                                src="/assets/images/icons/user-admin.svg"
                                alt="User image"
                              />
                            </figure>
                          </button>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="col-12 col-reset pt-3 pb-4">
                  <a
                    href="/registrar-una-mujer"
                    className="btn btn--type2 btn--100"
                  >
                    Registrar nueva amiga
                  </a>
                </div>
                <div className="col-12 col-reset d-flex justify-content-center">
                  <button type="button" className="btn--danger">
                    Eliminar Colectiva
                  </button>
                </div>
              </div>
            </aside>
            <div className="dashboard-info col-md-9 col-12">
              <h4 className="mb-4">Mujeres que acompañas</h4>
              <ColectivaTableComponent />
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default ColectivaDetailScreen;
