import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IAdmin } from '../../../types/adminInterface';
import { fetchStrapiDocs } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import LoaderComponent from '../../Loader/loader.component';

interface AmigaColectivaComponentProps {
  admin: IAdmin | undefined;
}

const AmigaColectivaComponent: React.FC<AmigaColectivaComponentProps> = ({
  admin: prevAdmin,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [selectedColectiva, setSelectedColectiva] = useState<string>('default');
  const [colectivaList, setColectivaList] = useState<strapiDoc[]>([]);
  const isButtonDisabled = selectedColectiva === 'default';

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const colectiva = colectivaList.find(
      (colectiva) => colectiva.id === Number(event.target.value)
    );
    setAdmin({
      ...admin,
      colectiva: {
        id: colectiva?.id,
        name: colectiva?.attributes.nombre,
      },
    });
    setSelectedColectiva(event.target.value);
  };

  const handleSubmit = () => {
    const stateAdmin = { ...admin, ...prevAdmin };
    navigate('/registrar-una-mujer-acceso', { state: { admin: stateAdmin } });
  };

  const fetchColectivas = async () => {
    try {
      setLoading(true);
      const query = {
        sort: ['nombre:asc'],
        filters: { pais: { $eq: 4 } }, // 4 es el id de Chile
        pagination: { pageSize: 100 },
        populate: '*',
      };
      const colectivas = await fetchStrapiDocs('/colectivas', query);
      setColectivaList(colectivas.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setAdmin({ ...admin, state: prevAdmin?.state });
      setSelectedColectiva(prevAdmin?.colectiva?.id?.toString() || 'default');
      console.log('admin', admin);
    }
  };

  useEffect(() => {
    if (prevAdmin) {
      fetchColectivas();
    }
  }, []);

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label className="form-label label--icon">
            Colectiva <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Listado de colectivas disponibles.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select requiredField"
            aria-label="Rol"
            defaultValue={'default'}
            name="rol"
            value={selectedColectiva}
            onChange={handleSelectChange}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {(colectivaList || []).map((zone, index) => (
              <option key={index} value={zone.id}>
                {zone.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row  form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={isButtonDisabled}
          >
            Siguiente
          </button>
        </div>
        <div className="form-row form--last">
          <a
            className="btn btn--type2"
            onClick={(e) => {
              e.preventDefault();
              navigate('/registrar-una-mujer-informacion', {
                state: { admin: prevAdmin },
              });
            }}
          >
            Regresar
          </a>
        </div>
      </form>
    </div>
  );
};

export default AmigaColectivaComponent;
