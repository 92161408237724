import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

interface ModalDerivarProps {
  show: boolean;
  onHide: () => void;
  onNext: () => void;
  onNextTwo: () => void;
}

const ModalDerivarComponents: React.FC<ModalDerivarProps> = ({ show, onHide, onNext, onNextTwo }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [selectedOption, setSelectedOption] = useState(''); // Estado para la opción seleccionada

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every(field => {
      const inputField = field as HTMLInputElement; // Aserción de tipo a HTMLInputElement
  
      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some(f => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  useEffect(() => {
    // Llama a checkFormValidity cuando el modal se muestra
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Derivar a otra profesional</h2>
          <p className="text-24 text-center">Recuerda registrar los detalles por los que la mujer necesita ser derivada a otra profesional</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Hacia dónde deseas que se derive este perfil?
            </label>
            <div className="col-12 mb-3">
              <div className="form-check">
                <input
                  className="form-check-input requiredField"
                  type="radio"
                  name="derivar"
                  id="lasAmigas"
                  value="lasAmigas"
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    checkFormValidity();
                  }}
                />
                <label className="form-check-label" htmlFor="lasAmigas">
                  Profesional de Las Amigas
                </label>
              </div>
            </div>
            <div className="col-12  mb-3">
              <div className="form-check">
                  <input
                    className="form-check-input requiredField"
                    type="radio"
                    name="derivar"
                    id="otra"
                    value="otra"
                    onChange={(e) => {
                      setSelectedOption(e.target.value);
                      checkFormValidity();
                    }}
                  />
                  <label className="form-check-label" htmlFor="otra">
                    A otra colectiva
                  </label>
                </div>
              </div>
            <div className="col-12  mb-3">
              <div className="form-check">
                <input
                  className="form-check-input requiredField"
                  type="radio"
                  name="derivar"
                  id="oirs"
                  value="oirs"
                  onChange={(e) => {
                    setSelectedOption(e.target.value);
                    checkFormValidity();
                  }}
                />
                <label className="form-check-label" htmlFor="oirs">
                  OIRS
                </label>
              </div>
            </div>
          </div>
          
          {selectedOption === 'lasAmigas' && (
            <>
              <div className="form-row">
                <label className="form-label label--icon">
                  Selecciona a la profesional de OLA a la que la quieras derivar <small className="required">*</small>
                </label>
                <select
                  className="form-select requiredField"
                  aria-label="Selecciona a la profesional de Las Amigas a la que la quieras derivar"
                  defaultValue={'default'}
                  name="profesional"
                  onChange={checkFormValidity}
                >
                  <option value="default" disabled>Elige una de las opciones</option>
                  <option>Option 1</option>
                  <option>Option 2</option>
                  <option>Option 3</option>
                </select>
              </div>
              <div className="form-row">
                <label className="form-label label--icon">
                  Elige el o los motivos para derivar a la mujer con otra profesional <small className="required">*</small>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        Elige el o los motivos para derivar a la mujer con otra profesional
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <p className="text-gray800">Puedes seleccionar más de una opción si es tu caso</p>
                <div className="row-check-2 row">
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="motivosDerivar"
                        id="adicional"
                        value="Necesita ayuda adicional"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="adicional">
                        Necesita ayuda adicional
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="motivosDerivar"
                        id="area"
                        value="No es mi área de especialidad"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="area">
                        No es mi área de especialidad
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="motivosDerivar"
                        id="message"
                        value="Dejó de contestar los mensajes"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="message">
                        Dejó de contestar los mensajes
                      </label>
                    </div>
                  </div>
                  <div className="row-check-2__col col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input requiredField"
                        type="checkbox"
                        name="motivosDerivar"
                        id="otro"
                        value="Otras razones"
                        onChange={checkFormValidity}
                      />
                      <label className="form-check-label" htmlFor="otro">
                        Otras razones
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <label className="form-label">
                  ¿Cuáles son los motivos adicionales de la derivación?
                  <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        ¿Cuáles son los motivos adicionales de la derivación?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Ingresa los motivos adicionales"
                  id="motivos"
                ></textarea>
              </div>
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          {selectedOption === 'lasAmigas' && (
            <button type="submit" className="btn btn--type1 btn--370" onClick={onHide} disabled={!isFormValid}>
              Solicitar derivación
            </button>
          )}
          {selectedOption === 'otra' && (
            <button type="submit" className="btn btn--type1 btn--370"
              onClick={() => {
                onHide();
                onNext();
              }}
            >
              Siguiente
            </button>
          )}
          {selectedOption === 'oirs' && (
            <button type="submit" className="btn btn--type1 btn--370"
              onClick={() => {
                onHide();
                onNextTwo();
              }}
            >
              Siguiente
            </button>
          )}
         
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type2 btn--370" onClick={onHide}>
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarComponents;
