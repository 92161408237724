import React from 'react';
import { IAdmin } from '../../../types/adminInterface';

interface Props {
  admin: Partial<IAdmin>;
}

const Step6Component: React.FC<Props> = ({ admin }) => {
  return (
    <>
      <div className="col-12">
        <h3 className="h2 text-400">¿Qué quiere decir esto?</h3>
        <div className="col-lg-10 col-12 mx-auto text-center">
          <p>
            Podrás acceder a la plataforma con el correo electrónico{' '}
            <strong>{`${admin.email || ''}`}</strong> y la contraseña <br />
            <br />
            <strong className="text-green300 text-32">{`${
              admin.password || ''
            }`}</strong>
          </p>
          <p>Guarda esta información ya que sólo se mostrará una vez.</p>
        </div>
        <div className="alert alert--simple-check col-md-6 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20">
            <strong>
              Revisaremos a detalle tu solicitud para ser gestora. Mantente
              pendiente de tu correo.
            </strong>
          </span>
        </div>
      </div>
    </>
  );
};

export default Step6Component;
