import React from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import { IAdmin } from '../../../types/adminInterface';
import { useLocation, useNavigate } from 'react-router-dom';
import GestoraStep3Component from '../../../components/Auth/Gestora/gestoraStep3.component';

const GestoraComunitariaCompromisoScreen: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const admin = state.admin as IAdmin | undefined;

  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="module-bottom40 col-12">
              <StepperComponent activeStep={4} />
            </div>
            <div className="col-12 mb-4">
              <a
                href="#"
                className="btn--icon btn--simple btn--simple--small"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/gestora-paso-3', { state: { admin } });
                }}
              >
                <i className="icon fa-solid fa-arrow-left"></i> Regresar
              </a>
            </div>
            {admin && <GestoraStep3Component admin={admin} paso={4} />}
          </div>
        </div>
      </section>

      <FooterShared />
    </>
  );
};

export default GestoraComunitariaCompromisoScreen;
