import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { deleteSession, getSession } from '../utils/helpers';
import { AdminPrincipalRol, IAdmin } from '../types/adminInterface';

const HeaderAdminShared: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [session, setSession] = useState<Partial<IAdmin>>({});

  const handleButtonClick = () => {
    setIsOpen(!isOpen); // Cambia el estado al opuesto de lo que está actualmente
  };
  const location = useLocation();

  const isActive = (path: string): string => {
    return location.pathname === path ? 'active' : '';
  };

  const handleLogOut = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    deleteSession();
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  useEffect(() => {
    const session = getSession();
    if (session) {
      setIsLogged(true);
      setSession(session);
    }
  }, []);

  return (
    <header>
      <div className="container">
        <div className="header__col col-12">
          <a href="/" className="header__logo">
            <figure>
              <img
                src={`${
                  isOpen
                    ? '/assets/images/logo/salud-logo-white.png'
                    : '/assets/images/logo/ola.png'
                }`}
                alt="OLA"
              />
            </figure>
          </a>
          <nav className={`main-nav ${isOpen ? 'active' : ''}`}>
            <ul>
              {isLogged ? (
                <>
                  {[
                    AdminPrincipalRol.ADMIN,
                    AdminPrincipalRol.GESTORA,
                  ].includes(session.principalRol as AdminPrincipalRol) ? (
                    <>
                      {/* <li>
                        <a
                          href="/colectivas"
                          className={isActive('/colectivas')}
                        >
                          Colectivas
                        </a>
                      </li> */}
                      <li>
                        <a
                          href="/equipo-ola"
                          className={isActive('/equipo-ola')}
                        >
                          Equipo OLA
                        </a>
                      </li>
                      <li>
                        <a href="/mujeres" className={isActive('/mujeres')}>
                          Mujeres
                        </a>
                      </li>
                      {session.principalRol === AdminPrincipalRol.ADMIN ? (
                        <>
                          <li>
                            <a
                              href="/solicitudes"
                              className={isActive('/solicitudes')}
                            >
                              Solicitudes
                            </a>
                          </li>
                          <li>
                            <a
                              href="/documentos"
                              className={isActive('/documentos')}
                            >
                              Documentos
                            </a>
                          </li>
                        </>
                      ) : null}
                      <li>
                        <a
                          href="/registrar-una-mujer"
                          className={`btn btn--type1`}
                        >
                          Registrar una mujer
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a
                          href="/profesional-inicio"
                          className={isActive('/profesional-inicio')}
                        >
                          Mujeres acompañando
                        </a>
                      </li>
                    </>
                  )}
                  <li className="main-nav__mobile">
                    <a href="/" className={isActive('/')}>
                      <strong>Cerrar sesión</strong>
                    </a>
                  </li>
                </>
              ) : (
                <li>
                  <a href="/" className={isActive('/')}>
                    Iniciar sesión
                  </a>
                </li>
              )}
            </ul>
          </nav>
          {isLogged ? (
            <div className="header__options header--admin">
              <div className="user-control">
                <button type="button" className="user-control-button">
                  <i className="icon icon--superadmin"></i>
                </button>
                <div className="user-options">
                  <div className="user-options__top">
                    <i className="icon icon--user-big"></i>
                    <article>
                      <h3 className="text-regular text-20">
                        {session.username || ''}
                      </h3>
                      <Link to="/mi-perfil">
                        <span>Ver perfil</span>
                      </Link>
                    </article>
                  </div>
                  <div className="user-options__bottom">
                    <ul>
                      <li>
                        <a href="#" onClick={handleLogOut}>
                          <i className="icon icon--logout"></i>
                          <span>Cerrar sesión</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="form-row form--search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar entre las amigas..."
                />
                <i className="icon icon--search-bar"></i>
              </div>
              <a href="/" className="icon-header ">
                <i className="icon icon--message"></i>
              </a> */}
            </div>
          ) : (
            ''
          )}
          <button
            type="button"
            className={`open-nav ${isOpen ? 'active' : ''}`}
            onClick={handleButtonClick}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdminShared;
