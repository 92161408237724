import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface InfoDataProps {
}

const InfoDataComponent: React.FC<InfoDataProps> = () => {

  return (
    <div className="form-block">
       <form >
        <div className="form-row">
          <label htmlFor="ocupacion-1" className="form-label label--icon">
            Ocupación 1
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Ocupación del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-1"
            placeholder="Ocupación 1"
            value={'Trabajadora dependiente'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="ocupacion-2" className="form-label label--icon">
            Ocupación 2
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Ocupación del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="ocupacion-2"
            placeholder="Ocupación 2"
            value={'Trabajo de cuidado no remunerado'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Nivel educativo
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Nivel educativo del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Nivel educativo"
            value={'Universitaria completada'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Pueblo originario
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Pueblo originario del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Pueblo originario"
            value={'No'}
            
          />
        </div>
        <div className="form-row">
          <label htmlFor="nivel-educativo" className="form-label label--icon">
            Origen Afrodescendiente
            <OverlayTrigger
              placement='bottom'
              overlay={
                <Tooltip >
                  Origen Afrodescendiente del usuario
                </Tooltip>
              }
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="nivel-educativo"
            placeholder="Origen Afrodescendiente"
            value={'No'}
            
          />
        </div>
       
      </form>
    </div>
  );
};

export default InfoDataComponent;