import React, { useEffect, useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import Markdown from 'react-markdown';
import Swal from 'sweetalert2';
import {
  AdminPrincipalRol,
  AdminStatus,
  AdminSteps,
  IAdmin,
} from '../../../types/adminInterface';
import { IPaso, IPregunta } from '../../../types/strapiDocs';
import { fetchStrapiSingle } from '../../../api/strapi';
import LoaderComponent from '../../Loader/loader.component';
import { signUp } from '../../../api/adminApi';
import { saveSession } from '../../../utils/helpers';

interface OlaInfoComponentProps {
  admin: IAdmin;
  paso: number;
}

const GestoraStep3Component: React.FC<OlaInfoComponentProps> = ({
  admin: prevAdmin,
  paso,
}) => {
  const [loading, setLoading] = useState(false);
  const [formularioValido, setFormularioValido] = useState(true);
  const [sectionContent, setSectionContent] = useState<IPaso>();
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});

  const navigate = useNavigate();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    }).then((resp) => {
      if (resp.isConfirmed) {
        setLoading(false);
        navigate(-1);
      }
    });
  };

  const verifyFields = (admin: Partial<IAdmin>) => {
    const adminQuestions = admin.questions || [];
    const preguntas = sectionContent?.preguntas || [];
    const preguntasRequeridas = preguntas.filter(
      (pregunta) => pregunta.requiredField
    );
    const preguntasRespondidas = preguntasRequeridas.filter((pregunta) =>
      adminQuestions.some(
        (adminQuestion) =>
          adminQuestion.id === pregunta.id && adminQuestion.value !== ''
      )
    );

    const hasContent =
      preguntasRequeridas.length && preguntasRespondidas.length;
    if (!hasContent) {
      setFormularioValido(false);
      return;
    }

    if (preguntasRequeridas.length === preguntasRespondidas.length) {
      setFormularioValido(true);
    } else {
      setFormularioValido(false);
    }
  };

  const fetchSection = async () => {
    try {
      const response = await fetchStrapiSingle('/registro-gestora', {
        populate: {
          '0': 'paso',
          paso: {
            populate: '*',
          },
        },
      });
      if (response.data) {
        const pasos = response.data.attributes.paso || [];
        if (pasos.length > 0) {
          const foundedPaso = pasos.find((p) => p.paso === paso);
          setSectionContent(foundedPaso);
        }
      }
    } catch (error) {
      console.error('Error al obtener la sección:', error);
      setError('Error al obtener la sección');
    }
  };

  const setAdminQuestion = (question: IPregunta, value: string) => {
    const adminQuestions = admin.questions || [];
    const existingQuestion = adminQuestions.find((q) => q.id === question.id);
    if (existingQuestion) {
      existingQuestion.value = value;
      existingQuestion.name = question.Nombre || '';
    } else {
      adminQuestions.push({
        id: question.id,
        value,
        name: question.Nombre || '',
      });
    }
    setAdmin({ ...admin, questions: adminQuestions });
  };

  const renderQuestion = (question: IPregunta) => {
    const { id, showMoreDesc, type, requiredField } = question;
    const options = question.options || [];
    const option = options.find((option) => option.type === 'list');
    const optionValues =
      option?.children?.map((child) => child.children[0]?.text || '') || [];
    const currentAdmin = { ...admin, ...prevAdmin };
    const currentValue =
      currentAdmin.questions?.find((question) => question.id === id)?.value ||
      '';

    if (type === 'radio') {
      return (
        <div className="form-check-multi">
          {optionValues.map((option, index) => (
            <div key={`option-${index}`} className="form-check">
              <input
                className={`form-check-input ${
                  requiredField ? 'requiredField' : ''
                }`}
                type="radio"
                name={`question-${id}`}
                id={`question-${id}-${index}`}
                value={option}
                checked={currentValue === option}
                onChange={(e) => {
                  const selectedOption = e.target.value;
                  setAdminQuestion(question, selectedOption);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={`question-${id}-${index}`}
              >
                {option}
              </label>
            </div>
          ))}
        </div>
      );
    }

    if (type === 'text') {
      return (
        <input
          type="text"
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setAdminQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <textarea
          className={`form-control ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          placeholder={showMoreDesc}
          rows={3}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setAdminQuestion(question, value);
          }}
        />
      );
    }

    if (type === 'select') {
      return (
        <select
          className={`form-select ${requiredField ? 'requiredField' : ''}`}
          id={`question-${id}`}
          value={currentValue}
          onChange={(e) => {
            const value = e.target.value;
            setAdminQuestion(question, value);
          }}
        >
          <option value="">Selecciona una opción</option>
          {optionValues.map((option, index) => (
            <option key={`option-${index}`} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
    }

    return <></>;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (paso === 5) {
      try {
        setLoading(true);
        const result = await signUp({
          ...admin,
          step: AdminSteps.PROFILE_CREATED,
          principalRol: AdminPrincipalRol.ACOMPANANTE,
          status: AdminStatus.ACTIVE,
        });
        if (result) {
          saveSession(result);
          setTimeout(() => {
            navigate('/gestora-proceso', {
              state: { admin: admin, new: true },
            });
          }, 300);
        } else {
          setError('Error al enviar el formulario');
        }
      } catch (error) {
        setError('Error al enviar el formulario');
      } finally {
        setLoading(false);
      }
      return;
    }
    navigate(`/gestora-paso-${paso + 1}`, {
      state: {
        admin: {
          ...prevAdmin,
          ...admin,
        },
      },
    });
  };

  useEffect(() => {
    fetchSection().then(() => {
      setAdmin({
        ...admin,
        ...prevAdmin,
      });
      verifyFields({
        ...admin,
        ...prevAdmin,
      });
    });
  }, []);

  useEffect(() => {
    verifyFields(admin);
    console.log({ ...admin, ...prevAdmin });
  }, [admin]);

  return (
    <div className="col-lg-5 col-12">
      {loading && <LoaderComponent />}
      <h1>{sectionContent?.header}</h1>
      <article className="text-20 col-12 col-reset">
        <p>{sectionContent?.subheader}</p>
        <Markdown
          allowedElements={['p']}
          unwrapDisallowed={false}
          components={{
            p: ({ children }) => <p className="text-green350">{children}</p>,
          }}
        >
          {sectionContent?.content}
        </Markdown>
      </article>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          {sectionContent?.preguntas?.map((pregunta, index) => {
            const { Nombre, tooltip, requiredField } = pregunta;
            return (
              <div className="form-row" key={index}>
                <label className="form-label label--icon">
                  {Nombre}{' '}
                  {requiredField && <small className="required">*</small>}
                  {tooltip && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>{tooltip}</Tooltip>}
                    >
                      <span className="material-icons-outlined icon">
                        help_outline
                      </span>
                    </OverlayTrigger>
                  )}
                </label>
                {renderQuestion(pregunta)}
              </div>
            );
          })}
          {/* Botón de envío */}
          <div className="form-row form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Siguiente
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GestoraStep3Component;
