import React from 'react';
import { Modal} from 'react-bootstrap';

interface ModalDisabledSuccessComponentProps {
  show: boolean;
  onHide: () => void;
}

const ModalDisabledSuccessComponent: React.FC<ModalDisabledSuccessComponentProps> = ({ show, onHide }) => {
  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
      
      <Modal.Body>
        <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img src="/assets/images/icons/checked.svg" alt="Success" />
            </figure>
          </div>
          <h2 className="text-40 text-center mb-4">¡Cuenta desactivada<br/> temporalmente!</h2>
          <p className="text-center text-24">Se desactivó la cuenta de:</p>
          <p className="text-center text-24"><strong>PAULINA PIMENTEL</strong> (no. Ficha 273923)</p>
          <p className="text-center text-24">Los cambios ya podrán ser visibles en su ficha</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="button" className="btn btn--type1 btn--290" onClick={onHide}>Regresar a ficha</button>
        </div>
       
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalDisabledSuccessComponent;