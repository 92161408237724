import React from 'react';
import HeaderShared from '../../shared/header.shared';
import FooterShared from '../../shared/footer.shared';

const NotFoundScreen: React.FC= () => {
  return (
    <>
      <HeaderShared />
      <section className="module">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
              <figure className="image-270">
                <img src="/assets/images/illustration/ilustration1.png" alt="Página no encontrada" />
              </figure>
            </div>
            <div className="col-md-8 col-12 text-center mt-4 mx-auto">
              <article>
                <h1 className="h3 text-regular">No encontramos la página que buscabas</h1>
                <p>Lamentamos informarte que la página que buscas no existe o ha sido movida. Por favor, utiliza la navegación principal para encontrar lo que necesitas o vuelve a la página de inicio para comenzar de nuevo.</p>
                <a href="/" className="btn btn--type1">Ir al inicio</a>
              </article>
            </div>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  )
}

export default NotFoundScreen;
