import axios from 'axios';
import {
  IChatRoom,
  IUserChat,
  IUserChatPaginate,
} from '../types/adminInterface';

const chatApi = axios.create({
  baseURL: 'https://olachat.manifesto.design/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const fetchChatMessages = async (roomId: string, token: string) => {
  try {
    const response = await chatApi.get<IUserChatPaginate>('/messages-admin', {
      params: { roomId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};

const publishMessage = async (body: IUserChat, token: string) => {
  try {
    const response = await chatApi.post(
      '/messages-admin',
      { ...body },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error publishing message:', error);
    throw error;
  }
};

const getChatRoom = async (idA: string, idB: string, token: string) => {
  try {
    const response = await chatApi.get<IChatRoom>('/chat-rooms', {
      params: { idA, idB },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat room:', error);
    throw error;
  }
};

const newChatRoom = async (body: IChatRoom, token: string) => {
  try {
    const response = await chatApi.post(
      '/chat-rooms',
      { ...body },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating chat room:', error);
    throw error;
  }
};

export { fetchChatMessages, publishMessage, getChatRoom, newChatRoom };
