export const TAGS = [
  {id: 'origen-ola', text: 'Origen: OLA', className: 'suggestion-class'},
  {id: 'perfil-linea', text: 'Perfil: Línea',className: 'suggestion-class'},
  {id: 'origen-ive', text: 'Origen: IVE', className: 'suggestion-class'},
  {id: 'repeticion', text: 'Repetición', className: 'suggestion-class'},
  {id: 'acude-a-hospital', text: 'Acude a hospital', className: 'suggestion-class'},
  {id: 'vitaminas-dosis-extra', text: 'Vitaminas dosis extra', className: 'suggestion-class'},
  {id: 'transferencia', text: 'Transferencia', className: 'suggestion-class'},
  {id: 'inasistente a taller', text: 'Inasistente a taller', className: 'suggestion-class'},
  {id: 'no-da-aporte', text: 'No da aporte', className: 'suggestion-class'},
  {id: 'vcm', text: 'VCM', className: 'suggestion-class'},
  {id: 'causales-c1', text: 'C1', className: 'suggestion-class'},
  {id: 'causales-c2', text: 'C2', className: 'suggestion-class'},
  {id: 'causales-c3', text: 'C3', className: 'suggestion-class'},
  {id: 'continua-embarazo', text: 'Continua embarazo', className: 'suggestion-class'},
  {id: 'estafa', text: 'Estafa', className: 'suggestion-class'},
]

export const TAGS_DATA = [
  { id: 'tag-1', className: 'badge--lasamigas', text: 'DERIVADA A LAS AMIGAS' },
  { id: 'tag-2', className: 'badge--medica', text: 'DERIVADA DE ESPECIALIDAD MÉDICA' },
  { id: 'tag-3', className: 'badge--colectiva', text: 'DERIVADA DE OTRA COLECTIVA' },
  { id: 'tag-4', className: 'badge--warning badge--icon', text: 'ES MENOR DE EDAD', icon: 'report_problem' },
  { id: 'tag-5', className: 'badge--danger badge--icon', text: 'Situación de violencia', icon: 'report_problem' },
  { id: 'tag-6', className: 'badge--psicologica badge--icon', text: 'Contención psicológica', icon: 'report_problem' },
  { id: 'tag-7', className: 'badge--complejidad badge--icon', text: 'Alta complejidad', icon: 'report_problem' },
  { id: 'tag-8', className: 'badge--discapacidad badge--icon', text: 'Discapacidad', icon: 'report_problem' },
  { id: 'tag-9', className: 'badge--no-spanish badge--icon', text: 'No habla español', icon: 'report_problem' },
  { id: 'tag-10', className: 'badge--active badge--icon', text: 'Cuenta activa', icon: 'verified' },
  { id: 'tag-11', className: 'badge--inactive badge--icon', text: 'CUENTA DESACTIVADA TEMPORALMENTE', icon: 'cancel' },
  { id: 'tag-12', className: 'badge--derivar badge--icon', text: 'SE SOLICITA DERIVARLA', icon: 'report_problem' },
];
