import React, {useState} from 'react';
import HeaderAdminShared from '../../../../shared/headerAdmin.shared';
import FooterShared from '../../../../shared/footer.shared';
import GlobalScoreComponent from '../../../../components/Elements/Quality/globalScore.component';
import StarScore from '../../../../components/Elements/Quality/starScore.component';
import { SCORE_DATA } from '../../../../utils/score'
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import CustomPagination from '../../../../components/Elements/Paginator/paginator.component';

const EvaluationAdminScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = SCORE_DATA.slice(indexOfFirstItem, indexOfLastItem);
  const handleChangePage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
       <HeaderAdminShared />
        <section className="module container">
          <div className="module-bottom40 row">
            <div className="col-12">
              <PrivacyComponent />
            </div>
          </div>
          <div className="body-row row">
            <div className="col-12">
            
              <div className="auth-row row">
                <div className="col-12 mb-4">
                  <a href="/detalle-acompanante" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
                </div>
                <div className="col-12 mb-4">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <h1>Rafaela</h1>
                      <p className="text-20">Acompañante</p>
                    </div>
                    <aside className="col-md-6 col-12">
                      <div className="row">
                        <div className="col-md-4 col-12">
                          <GlobalScoreComponent
                            type="Acompañamiento"
                            score={4.5}
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <GlobalScoreComponent
                            type="Comunicación"
                            score={5.0}
                          />
                        </div>
                        <div className="col-md-4 col-12">
                          <GlobalScoreComponent
                            type="Seguimiento"
                            score={4.8}
                          />
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <h2 className="h3 mb-4">Evaluaciones y calificaciones</h2>
                  {currentItems.map((item) => (
                    <div className="quality-score-info" key={item.id}>
                      <p>
                        <strong>{item.user} - {item.date}</strong>
                      </p>
                      <div className="form-row">
                        <textarea className="form-control form--filled" placeholder="Reseña de usuario" value={item.message} disabled></textarea>
                      </div>
                      <div className="quality-score-list">
                        {item.types.map((typeItem) => (
                          <StarScore
                            key={typeItem.label}
                            type={typeItem.label}
                            score={typeItem.score}
                          />
                        ))}
                      </div>
                      <hr />
                    </div>
                  ))}
                </div>
                <div className="col-12 d-flex justify-content-center">
                <div className="data-paginator">
                    <CustomPagination
                      rowsPerPage={itemsPerPage}
                      rowCount={SCORE_DATA.length}
                      onChangePage={handleChangePage}
                      currentPage={currentPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       <FooterShared />
    </>
  );
};

export default EvaluationAdminScreen;