import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalNotesProps {
  show: boolean;
  onHide: () => void;
}
const ModalDerivarGrupoEndComponents: React.FC<ModalNotesProps> = ({ show, onHide }) => {
  
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--checked"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Perfil derivado a otra colectiva u organización</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="text-24">Has solicitado que <strong>Paulina</strong> sea derivada a Las Amiga.</p>
        <p className="text-24">Su información será enviada hacia la plataforma y su perfil será vinculado con esta colectiva</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="submit" className="btn btn--type1 " onClick={onHide}>Regresar a mujeres asignadas</button>
        </div>
       
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarGrupoEndComponents;
