import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AdminSteps, IAdmin } from '../../../types/adminInterface';
import LoaderComponent from '../../Loader/loader.component';
import { newAdminInvitations, signUp } from '../../../api/adminApi';
import { getSession } from '../../../utils/helpers';

interface AmigaAuthComponentProps {
  admin: IAdmin | undefined;
}

const AmigaAuthComponent: React.FC<AmigaAuthComponentProps> = ({
  admin: prevAdmin,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [formularioValido, setFormularioValido] = useState(false);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    }).then((resp) => {
      if (resp.isConfirmed) {
        setLoading(false);
        navigate('/registrar-una-mujer', { state: { admin } });
      }
    });
  };

  const adminSignUp = async (admin: IAdmin) => {
    try {
      setLoading(true);
      const session = getSession();
      if (!session) {
        throw new Error('No se pudo obtener la sesión.');
      }
      const invitation = await newAdminInvitations(session.token || '');
      const singUp = await signUp({
        ...admin,
        invCode: invitation.invCode,
        step: AdminSteps.ACTIVE,
      });
      if (singUp) {
        setLoading(false);
        navigate('/registrar-una-mujer-finalizado', {
          state: { admin: singUp },
        });
      }
    } catch (error) {
      console.error(error);
      setError('Ocurrió un error al registrar la amiga.');
    }
  };

  const onFieldChange = (name: keyof IAdmin, value: string) => {
    setAdmin({ ...admin, [name]: value });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;

    camposRequeridos.forEach((campo) => {
      if (campo.value === undefined || campo.value === null) {
        todosCompletos = false;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });
    setFormularioValido(todosCompletos);
  };

  useEffect(() => {
    verifyFields();
  }, [admin]);

  useEffect(() => {
    if (prevAdmin) {
      setAdmin({ ...admin, ...prevAdmin });
    }
  }, [prevAdmin]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const stateAdmin = { ...admin, ...prevAdmin } as IAdmin;
    adminSignUp(stateAdmin);
  };

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label className="form-label label--icon">
            Correo electrónico <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Correo electrónico del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="email"
            className="form-control requiredField"
            id="name"
            placeholder="Escribe el correo aquí"
            onChange={(e) => {
              onFieldChange('email', e.target.value);
            }}
          />
        </div>
        <div className="form-row">
          <label className="form-label label--icon">
            Teléfono <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Teléfono del usuario.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <input
            type="number"
            className="form-control requiredField"
            id="number"
            placeholder="Escribe el teléfono"
            onChange={(e) => {
              onFieldChange('phone', e.target.value);
            }}
          />
        </div>
        <div className="form-row form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={!formularioValido}
          >
            Crear cuenta
          </button>
        </div>
        <div className="form-row form--last">
          <a
            className="btn btn--type2"
            onClick={(e) => {
              e.preventDefault();
              navigate('/registrar-una-mujer-colectiva', {
                state: { admin: prevAdmin },
              });
            }}
          >
            Regresar
          </a>
        </div>
      </form>
    </div>
  );
};

export default AmigaAuthComponent;
