import React, { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AdminPrincipalRol, IAdmin } from '../../../../types/adminInterface';
import { searchAdmins } from '../../../../api/adminApi';
import { getSession } from '../../../../utils/helpers';
import { IUser, IUserAssignedTo } from '../../../../types/userInterface';

interface ModalAsignarComponentProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
  onSuccess: (assignedTo: IUserAssignedTo) => void;
  setLoading: (loading: boolean) => void;
  updateAmiga: (user: Partial<IUser>) => Promise<void>;
}

const ModalAsignarComponent: React.FC<ModalAsignarComponentProps> = ({
  user,
  show,
  onHide,
  onSuccess,
  setLoading,
  updateAmiga,
}) => {
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const [assignedTo, setAssignedTo] = useState<IUserAssignedTo>();

  const setError = (message: string) => {
    console.error(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    fetchAdmins(selectedValue as AdminPrincipalRol);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Evitar recarga de la página al enviar el formulario
    if (assignedTo) {
      const userAssignedTo = (user.assignedTo || []).map((assignedUser) => {
        return {
          ...assignedUser,
          adminId: (assignedUser.adminId as IAdmin)._id,
        };
      });
      const updatedUserAssignedTo = [...userAssignedTo, assignedTo];
      updateAmiga({
        assignedTo: updatedUserAssignedTo,
      }).then(() => {
        setAdmins([]);
        onSuccess(assignedTo);
      });
    }
  };

  const fetchAdmins = async (principalRol: AdminPrincipalRol) => {
    try {
      const session = getSession();
      if (!session) {
        setError('No se encontró la sesión');
        return;
      }
      setLoading(true);
      const response = await searchAdmins(
        0,
        50,
        { principalRol },
        session.token || ''
      );
      if (response) {
        setAdmins(
          response.docs.filter((admin) => {
            const isAlreadyAssigned = (user.assignedTo || []).some(
              (assignedTo) => (assignedTo.adminId as IAdmin)._id === admin._id
            );
            return !isAlreadyAssigned;
          })
        );
      }
    } catch (error) {
      console.error('Error al obtener los administradores:', error);
      setError('Error al obtener los administradores');
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="col-12 d-flex justify-content-center">
              <i className="icon icon--user-add"></i>
            </div>
            <div className="col-12">
              <h2 className="text-center"> Asignar profesional</h2>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <p className="mb-5 text-center text-24">
            Selecciona una acompañante de la Colectiva
          </p>
          <form onSubmit={handleSubmit}>
            <div className="pb-3 form-row">
              <label className="form-label label--icon">
                Selecciona el rol de profesional a asignar{' '}
                <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Selecciona el rol de profesional a asignar
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select requiredField"
                aria-label="Acompañante"
                defaultValue={'default'}
                name="acompanante"
                onChange={handleSelectChange}
              >
                <option value="default" disabled>
                  Selecciona una opción
                </option>
                {[
                  AdminPrincipalRol.ACOMPANANTE,
                  AdminPrincipalRol.ASESORA_LEGAL,
                  AdminPrincipalRol.PSICOLOGA,
                ].map((rol, index) => (
                  <option key={index} value={rol}>
                    {rol}
                  </option>
                ))}
              </select>
            </div>
            {admins.length > 0 && (
              <div className="pb-3 form-row">
                <label className="form-label label--icon">
                  Selecciona una de las gestoras{' '}
                  <small className="required">*</small>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Selecciona una de las gestoras</Tooltip>}
                  >
                    <span className="material-icons-outlined icon">
                      help_outline
                    </span>
                  </OverlayTrigger>
                </label>
                <select
                  className="form-select requiredField"
                  aria-label="Acompañante"
                  defaultValue={'default'}
                  name="acompanante"
                  onChange={(e) => {
                    const selectedAdminId = e.target.value;
                    const selectedAdmin = admins.find(
                      (admin) => admin._id === selectedAdminId
                    );
                    if (selectedAdmin) {
                      setAssignedTo({
                        adminId: selectedAdmin._id,
                        principalRol: selectedAdmin.principalRol,
                        username: selectedAdmin.username,
                      });
                    }
                  }}
                >
                  <option value="default" disabled>
                    Selecciona una opción
                  </option>
                  {admins.map((admin, index) => (
                    <option key={index} value={admin._id}>
                      {`${admin.username} ${admin.lastName || ''}`}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="form-row d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn--type1 btn--290"
                disabled={!assignedTo?.adminId}
              >
                Asignar
              </button>
            </div>
            <div className="form-row d-flex justify-content-center">
              <button
                type="button"
                className="btn btn--type2 btn--290"
                onClick={handleModalClose}
              >
                Cancelar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalAsignarComponent;
