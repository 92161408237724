import React from 'react';

interface DonationsComponentProps {
  number: number;
  date: string;
  clp?: boolean;
  size?: 'small' | 'large';
}

const DonationsInitComponent: React.FC<DonationsComponentProps> = ({ number, date, clp, size }) => {
  // Obtener la parte entera y la parte decimal del número
  const integerPart = Math.floor(number);
  const decimalPart = (number - integerPart).toFixed(2).slice(2);

  // Implementa tu lógica del componente aquí

  return (
    <>
      <div className="data-number-currency">
        <p className="data-number-currency__init mb-0"><small className="text-24 text-500">$</small><span className={`${size == 'large' ? 'text-64': 'text-32'} text-300`}>{integerPart.toLocaleString()}</span></p>
        <div> 
          {decimalPart !== '00' && (
            <p className="text-24 mb-0">.{decimalPart}</p>
          )}
          {clp && (
            <p className="text-24 mb-0">CLP</p>
          )}
        </div>
      </div>
      <p className="text-14">Último registro: {date}</p>
    </>
  );
};

export default DonationsInitComponent;
