import React, { useState, useEffect } from 'react';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import { AdminSteps, IAdmin } from '../../../types/adminInterface';
import { deleteSession, getSession } from '../../../utils/helpers';
import Swal from 'sweetalert2';

interface Props {
  // Define your component props here
}

const steps = [
  {
    title: 'Recibimos tu perfil',
    description: 'En proceso',
    status: 'status--process',
    completed: false,
    color: 'pink400',
  },
  {
    title: 'Agendamos una entrevista',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Conoce al equipo OLA',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Recibe tu acceso a OLA',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },
  {
    title: 'Empieza a hacer la diferencia',
    description: 'Pendiente',
    status: '',
    completed: false,
    color: 'gray600',
  },

  // Status color
  //-- In progress status--process - green350
  //-- In progress status--success - pink400
  //-- In progress status neutral '' - gray600
];

const GestoraComunitariaProcessScreen: React.FC<Props> = () => {
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});

  const fetchAdmin = () => {
    const session = getSession();
    if (session) {
      setAdmin(session);
      if (admin.email !== '' && admin.password?.length === 8) {
        Swal.fire({
          icon: 'success',
          title: '¡Bienvenida a OLA!',
          html: `
            <p>
              Podrás acceder a la plataforma con el correo electrónico
              <strong>${admin.email || ''}</strong> y la contraseña
              <br /><br />
              <strong>${admin.password || ''}</strong>
              <br /><br />
            </p>
            <p>Guarda esta información ya que sólo se mostrará una vez.</p>
          `,
        });
      }
      return;
    }
  };

  useEffect(() => {
    fetchAdmin();
  }, []);

  useEffect(() => {
    const sessionTime = 5 * 60 * 1000; // 5 minutos en milisegundos
    setTimeout(() => {
      deleteSession();
      window.location.href = '/';
    }, sessionTime);
  }, []);

  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-md-8 col-12">
                <h1 className="text-64 mb-3">
                  ¡{`${admin.username || ''}`}, gracias por registrarte como
                  acompañante!
                </h1>
                <p className="text-32 mb-0">
                  <strong>
                    Revisaremos cuidadosamente tu solicitud y nos pondremos en
                    contacto contigo
                  </strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              {admin.isActive && admin.step !== AdminSteps.PROFILE_REJECTED ? (
                <div className="col-12">
                  <div className="alert alert--simple-check col-md-6 col-12">
                    <span className="icon material-icons-outlined">
                      check_circle
                    </span>
                    <span className="text-20">
                      <strong>
                        Revisaremos a detalle tu solicitud para ser gestora.
                        Mantente pendiente de tu correo.
                      </strong>
                    </span>
                  </div>
                </div>
              ) : (
                <div className="col-12">
                  <div className="alert alert--soft-danger col-md-6 col-12">
                    <span className="icon material-icons-outlined">error</span>
                    <span className="text-20">
                      <strong>
                        Lamentamos informarte que tu solicitud no ha sido
                        rechazada. Ponte en contacto con nosotras para más
                        detalles.
                      </strong>
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default GestoraComunitariaProcessScreen;
