import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { strapiDoc } from '../../../types/strapiDocs';
import Swal from 'sweetalert2';
import LoaderComponent from '../../Loader/loader.component';
import { fetchStrapiDocs } from '../../../api/strapi';
import { AdminPrincipalRol, IAdmin } from '../../../types/adminInterface';

interface AmigaRolComponentProps {}

const AmigaRolComponent: React.FC<AmigaRolComponentProps> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState<Partial<IAdmin>>({});
  const [selectedRol, setSelectedRol] = useState<string>('default');
  const [principalRoles, setPrincipalRoles] = useState<strapiDoc[]>([]);
  const [secondaryRoles, setSecondaryRoles] = useState<strapiDoc[]>([]);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRol(event.target.value);
    if (event.target.value && event.target.value !== 'default') {
      fetchSecondaryRoles(Number(event.target.value));
      const selectedRol = principalRoles.find(
        (rol) => rol.id === Number(event.target.value)
      );
      setAdmin({
        ...admin,
        principalRol: selectedRol?.attributes.nombre as AdminPrincipalRol,
      });
    }
  };

  const handleSecondarySelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedRol = secondaryRoles.find(
      (rol) => rol.id === Number(event.target.value)
    );
    setAdmin({
      ...admin,
      secondaryRol: selectedRol?.attributes.nombre as AdminPrincipalRol,
    });
  };

  const isButtonDisabled = selectedRol === 'default';

  const handleSubmit = () => {
    if (admin.principalRol === AdminPrincipalRol.GESTORA) {
      navigate('/gestora-paso-1', { state: { admin } });
    } else {
      navigate('/registrar-una-mujer-informacion', { state: { admin } });
    }
  };

  const fetchSecondaryRoles = async (principalRoleId: number) => {
    try {
      const query = {
        sort: 'nombre:asc',
        filters: { admin_role_principal: { $eq: principalRoleId } },
      };
      const response = await fetchStrapiDocs('admin-roles-secundarios', query);
      setSecondaryRoles(response.data);
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = { sort: 'nombre:asc' };
        const response = await fetchStrapiDocs('admin-roles-principal', query);
        setPrincipalRoles(response.data);
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="form-block">
      {loading && <LoaderComponent />}
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label className="form-label label--icon">
            Rol <small className="required">*</small>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Rol de usuario a registrar.</Tooltip>}
            >
              <span className="material-icons-outlined icon">help_outline</span>
            </OverlayTrigger>
          </label>
          <select
            className="form-select requiredField"
            aria-label="Rol"
            defaultValue={'default'}
            name="rol"
            value={selectedRol}
            onChange={handleSelectChange}
          >
            <option value="default" disabled>
              Elige una de las opciones
            </option>
            {principalRoles.map((rol) => (
              <option key={rol.id} value={rol.id}>
                {rol.attributes.nombre}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          {secondaryRoles.length > 0 && (
            <>
              <label className="form-label label--icon">
                Rol secundario
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Rol secundario de usuario a registrar. (Opcional)
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Rol secundario"
                name="rolSecundario"
                onChange={handleSecondarySelectChange}
              >
                <option value="default">Elige una de las opciones</option>
                {secondaryRoles.map((rol) => (
                  <option key={rol.id} value={rol.id}>
                    {rol.attributes.nombre}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className="form-row  form--last">
          <button
            type="submit"
            className="btn btn--type1"
            disabled={isButtonDisabled}
          >
            Siguiente
          </button>
        </div>
      </form>
    </div>
  );
};

export default AmigaRolComponent;
