export const USERS_DATA = [
  {
    "name": "Paulina Pimentel",
    "id": "273923",
    "registrationDate": "18/01/2023 (24 horas)",
    "weeks": "6 + 3 días",
    "origin": "OLA",
    "cause": "2",
    "commune": "Valparaíso",
    "derivationReasons": [
      "Necesita ayuda adicional",
      "No es mi área de especialidad"
    ],
    "referringProfessional": "Atención psicológica",
    "detailUrl": "/detalle-usuario"
  },
  {
    "name": "Maria Rodriguez",
    "id": "384729",
    "registrationDate": "22/02/2023 (12 horas)",
    "weeks": "4 + 2 días",
    "origin": "OLA",
    "cause": "1",
    "commune": "Santiago",
    "derivationReasons": [
      "Necesita apoyo emocional",
      "Requiere orientación legal"
    ],
    "referringProfessional": "Atención social",
    "detailUrl": "/detalle-usuario"
    },
    {
    "name": "Ana Lopez",
    "id": "938475",
    "registrationDate": "10/03/2023 (48 horas)",
    "weeks": "8 + 1 días",
    "origin": "OLA",
    "cause": "3",
    "commune": "Concepción",
    "derivationReasons": [
      "Requiere asesoramiento laboral",
      "Necesita ayuda económica"
    ],
    "referringProfessional": "Atención social",
    "detailUrl": "/detalle-usuario"
    },
    {
    "name": "Laura Martinez",
    "id": "572938",
    "registrationDate": "05/04/2023 (72 horas)",
    "weeks": "10 + 4 días",
    "origin": "OLA",
    "cause": "2",
    "commune": "Antofagasta",
    "derivationReasons": [
      "Necesita apoyo psicológico",
      "Requiere orientación legal"
    ],
    "referringProfessional": "Atención psicológica",
    "detailUrl": "/detalle-usuario"
    }
]

