import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderShared from '../../../shared/header.shared';
import FooterShared from '../../../shared/footer.shared';
import StepperComponent from '../../../components/Stepper/stepper.component';
import GestoraStep2Component from '../../../components/Auth/Gestora/gestoraStep2.component';
import { IAdmin } from '../../../types/adminInterface';

const GestoraComunitariaDemograficosScreen: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const admin = state.admin as IAdmin | undefined;

  return (
    <>
      <HeaderShared />
      <section className="hero-intro module">
        <div className="container">
          <div className="row row-middle">
            <div className="module-bottom40 col-12">
              <StepperComponent activeStep={2} />
            </div>
            <div className="col-12 mb-4">
              <a
                href="#"
                className="btn--icon btn--simple btn--simple--small"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/gestora-paso-1', { state: { admin } });
                }}
              >
                <i className="icon fa-solid fa-arrow-left"></i> Regresar
              </a>
            </div>
            <div className="col-lg-5 col-12">
              <h1>Regístrate como acompañante</h1>
              <article className="text-20 col-12 col-reset">
                <p>
                  Para formar parte de nosotras como acompañante, necesito
                  hacerte algunas preguntas.
                </p>
                <p className="text-green350">
                  Recuerda que este proceso, así como tus datos están
                  protegidos.
                </p>
              </article>
              <h2 className="text-24">Tu información personal</h2>
              {admin && <GestoraStep2Component admin={admin} />}
            </div>
          </div>
        </div>
      </section>

      <FooterShared />
    </>
  );
};

export default GestoraComunitariaDemograficosScreen;
