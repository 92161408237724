import React from 'react';
import { Modal } from 'react-bootstrap';

interface ModalDeleteTagComponentProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

const ModalDeleteTagComponent: React.FC<ModalDeleteTagComponentProps> = ({ show, onHide, onConfirm }) => {
 
  const handleModalClose = () => {
    onHide();
  
  };


  const handleConfirmDelete = () => {
    onConfirm(); // Llamar a la función de confirmación
  };


  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray modal--md"
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center">
            <i className="icon icon--warning"></i>
          </div>
         
          <div className="col-12 text-center">
            <h2 className="text-center">Eliminar etiqueta</h2>
          </div>
        </Modal.Title>
       
      </Modal.Header>
      <Modal.Body>
        <p className="text-24 text-center">Deseas eliminar etiqueta:</p>
        <p className="text-24 text-center"><strong>ESTAFA</strong></p>
      </Modal.Body>
      <Modal.Footer>
      <div className="form-row d-flex justify-content-center">
        <button
            type="button"
            className="btn btn--type1 btn--180"
            onClick={handleConfirmDelete} 
          >Eliminar</button>
      </div>
      <div className="form-row d-flex justify-content-center">
      
            <button
            type="button"
            className="btn btn--type2 btn--180"
            onClick={handleModalClose} 
          >Cancelar</button>
      </div>
       
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalDeleteTagComponent;