import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DonationsBlockComponentProps {
  quantity: number;
  donations: number;
  increment: number;
  button?: boolean;
  graphic?: boolean;
  chartData?: ChartData<'bar', number[], unknown>;
}

const DonationsBlockComponent: React.FC<DonationsBlockComponentProps> = ({
  quantity,
  donations,
  increment,
  button,
  graphic,
  chartData,
}) => {
  const formattedQuantity = quantity.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedIncrement = increment.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const options = {
    animation: false,
    scales: {
      x: {
        grid: {
          display: false, // Esto oculta las líneas del grid en el eje x
        },
        
      },
      y: {
        grid: {
          display: false, // Esto oculta las líneas del grid en el eje y
        },
        ticks: {
          callback: function (value: number) {
            return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
          font: {
            family: 'DM Sans', // Cambia "Arial" por tu fuente personalizada
            size: 12, // Tamaño de la fuente
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      bar: {
        borderRadius: 4, 
      },
    },
  };


  return (
    <section className="dashboard-card dashboard--4">
     <div className="row">
      <div className={`dashboard-card__col ${!graphic ? 'col-12' : 'col-lg-6'} col-reset ${button && 'mb-5'}`}>
          <h2 className="h3 mb-4">Documentos acumulados</h2>
          <p className="text-32 text-500 mb-2">${formattedQuantity}</p>
          <p className="text-14">Recibidos: {donations.toLocaleString()} documentos</p>
          <p className="badge badge--success-alert"><span className="text-14 mb-0">Los ingresos han incrementado ${formattedIncrement} vs el mes anterior</span></p>
        </div>
        {button && (
          <a href="#" className="btn btn--type1">Ver movimientos</a>
        )}
        {graphic && chartData && (
          <div className="dashboard-card__col col-lg-6 col-12">
           <div className="chart-element">
            <Bar
                data={chartData}
                options={options as ChartOptions<'bar'>}
              />
           </div>
          </div>
        )}
     </div>
    </section>
  );
};

export default DonationsBlockComponent;
