import React, { useState } from 'react';
import { Accordion, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import activityData from '../../../utils/actividades.json';
import BitacoraItemComponent from '../../Elements/BitacoraItem/bitacoraItem.component';
import BitacoraItemSavedComponent from '../../Elements/BitacoraItem/bitacoraItemSaved.component';
import { format } from 'date-fns';

interface Props {
  // Define the props for your component here
}

interface Activity {
  id: string;
  label: string;
}

interface BitacoraItemData {
  id: string;
  date: string;
  hour: string;
  note: string;
}

const BitacorasComponent: React.FC<Props> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activity, setActivity] = useState(activityData);
  const [active, setActive] = useState<string>('default'); // Estado para la selección en el select
  const [selectedItems, setSelectedItems] = useState<Activity[]>([]); // Estado para los elementos añadidos
  const [bitacoraData, setBitacoraData] = useState<{ [key: string]: BitacoraItemData }>({});
  const [savedItems, setSavedItems] = useState<string[]>([]);
  const navigate = useNavigate();

  const handleActivityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActive(e.target.value);
  };

  const handleSaved = () => {
    const itemsToSave = selectedItems.filter(item => {
      const data = bitacoraData[item.id];
      return data && data.date && data.hour && data.note;
    }).map(item => item.id);

    setSavedItems(itemsToSave);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/detalle-usuario');
  };

  const handleAddActivity = () => {
    if (active === 'default') return;

    const selectedItem = activity.find((item) => item.id === active);
    if (selectedItem && !selectedItems.find((item) => item.id === active)) {
      setSelectedItems([...selectedItems, selectedItem]);
    }
  };

  const handleRemoveActivity = (id: string) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== id));
    setSavedItems(savedItems.filter((savedId) => savedId !== id));
    setBitacoraData((prevData) => {
      const newData = { ...prevData };
      delete newData[id];
      return newData;
    });
  };

  const handleBitacoraDataChange = (id: string, data: BitacoraItemData) => {
    setBitacoraData({
      ...bitacoraData,
      [id]: data,
    });
  };

  return (
    <>
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="nation" className="form-label label--icon">
              Selecciona una de las opciones a registrar <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Selecciona una de las opciones a registrar
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <div className="col-12 mb-4">
              <select
                className="form-select"
                aria-label="Tu nacionalidad"
                onChange={handleActivityChange}
                value={active}
              >
                <option value="default" disabled>Elige una de las opciones</option>
                {activity.map((item) => (
                  <option key={item.id} value={item.id}>{item.label}</option>
                ))}
              </select>
            </div>
            <Accordion defaultActiveKey="0" className="accordion-regular mb-4">
              {selectedItems.map((item) => (
                <div key={item.id} className="accordion-wrapp">
                  {savedItems.includes(item.id) ? (
                    <BitacoraItemSavedComponent
                      name={item.label}
                      date={format(new Date(bitacoraData[item.id]?.date || ''), 'yyyy/MM/dd')}
                      hour={bitacoraData[item.id]?.hour || ''}
                      note={bitacoraData[item.id]?.note || ''}
                    />
                  ) : (
                    <BitacoraItemComponent
                      name={item.label}
                      onChange={(data: BitacoraItemData) => handleBitacoraDataChange(item.id, data)}
                    />
                  )}
                  <div className="col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn--danger text-12"
                      onClick={() => handleRemoveActivity(item.id)}
                    >
                      Eliminar actividad
                    </button>
                  </div>
                </div>
              ))}
            </Accordion>
            <button
              className="btn btn--type2 btn--100 mb-4"
              type="button"
              onClick={handleAddActivity}
              disabled={active === 'default'}
            >
              <span className="material-icons-outlined">add</span> Agregar actividad
            </button>
          </div>
          <div className="form-row">
            <button
              type="button"
              className="btn btn--type3 btn--100"
              onClick={() => handleSaved()}
            >
              Guardar
            </button>
          </div>
          <div className="form-row">
            <button type="submit" className="btn btn--type1 btn--100">
              Finalizar
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BitacorasComponent;
