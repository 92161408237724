import React, { useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const ModalOirsComponent: React.FC<Props> = ({ show, onHide, onSuccess }) => {
  const [isFormValid, setIsFormValid] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const checkFormValidity = () => {
    const requiredFields = document.querySelectorAll('.requiredField');
    const allFieldsFilled = Array.from(requiredFields).every(field => {
      const inputField = field as HTMLInputElement;

      if (inputField.type === 'checkbox' || inputField.type === 'radio') {
        return Array.from(requiredFields).some(f => {
          const input = f as HTMLInputElement;
          return input.name === inputField.name && input.checked;
        });
      }
      return inputField.value.trim() !== '';
    });
    setIsFormValid(allFieldsFilled);
  };

  // Maneja el cambio del checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    if (show) {
      checkFormValidity();
    }
  }, [show]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isFormValid) {
      onSuccess();  // Llama a onSuccess para cerrar el modal actual y abrir el modal de éxito en el componente padre
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-40 text-center text-500 pb-2">Derivación a especialistas y/o OIRS</h2>
          <p className="text-24 text-center">Esta mujer será derivada a una especialista</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
        <div className="form-row">
              <label className="form-label label--icon">
                ¿Qué especialista deseas que tome este perfil?
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        ¿Qué especialista deseas que tome este perfil?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
              </label>
              <p className="text-gray600">Puedes seleccionar más de una opción si es el caso</p>
              <div className="row-check-2 row">
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="perfil"
                      id="abogada"
                      value="abogada"
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="abogada">
                      Abogada
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="perfil"
                      id="psicologa"
                      value="psicologa"
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="psicologa">
                      Psicóloga
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="perfil"
                      id="gestora"
                      value="gestora"
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="gestora">
                      Gestora comunitaria
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="perfil"
                      id="psiquiatra"
                      value="psiquiatra"
                      onChange={checkFormValidity}
                    />
                    <label className="form-check-label" htmlFor="psiquiatra">
                     Psiquiatra
                    </label>
                  </div>
                </div>
                <div className="row-check-2__col col-6">
                  <div className="form-check">
                    <input
                      className="form-check-input requiredField"
                      type="checkbox"
                      name="perfil"
                      id="otro"
                      value="otro"
                      onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="otro">
                     Otro
                    </label>
                  </div>
                </div>
                {isChecked && (
                  <div className="row-check-2__col col-4">
                    <label className="form-check-label" htmlFor="otro">
                      ¿Cuál?
                      </label>
                    <input
                        className="form-control requiredField"
                        type="text"
                        name="perfil"
                        id="other"
                        placeholder='Ingresa el perfil a canalizar'
                        onChange={checkFormValidity}
                      />
                      
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
                ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                      <Tooltip>
                        ¿Se ha iniciado ya un reclamo OIRS para esta mujer?
                      </Tooltip>
                    }
                  >
                    <span className="material-icons-outlined icon">help_outline</span>
                  </OverlayTrigger>
              </label>
              <div className="form-check-multi">
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="motivo" id="si" value="si" defaultChecked  onChange={checkFormValidity}/>
                  <label className="form-check-label" htmlFor="si">
                  Sí
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="motivo" id="no" value="no" defaultChecked  onChange={checkFormValidity}/>
                  <label className="form-check-label" htmlFor="no">
                  No
                  </label>
                </div>
                <div className="form-check">
                  <input className="form-check-input requiredField" type="radio" name="motivo" id="noSeguro" value="noSeguro" defaultChecked  onChange={checkFormValidity}/>
                  <label className="form-check-label" htmlFor="noSeguro">
                  No estoy segura
                  </label>
                </div>
              
              </div>
            </div>
            <div className="form-row">
              <label className="form-label label--icon">
              ¿Cuáles son los motivos de la derivación?
              </label>
              <textarea
                className="form-control"
                placeholder="Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el por qué de esta decisión."
                id="motivosAdicionales"
              ></textarea>
            </div>
          <div className="form-row">
            <div className="col-12 d-flex justify-content-center mb-3">
              <button type="submit" className="btn btn--type1 btn--370" disabled={!isFormValid}>
              Derivar perfil a especialista
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type2 btn--370" onClick={onHide}>
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalOirsComponent;
