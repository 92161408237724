import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import ModalAsignarComponent from '../Modal/ModalAsignar/modalAsignar.component';
import ModalAsignarSuccessComponent from '../Modal/ModalAsignar/modalAsignarSuccess.component';
import ModalCambiarColectivaComponent from '../Modal/ModalCambiarColectiva/modalCambiar.component';
import ModalCambiarColectivaSuccessComponent from '../Modal/ModalCambiarColectiva/modalCambiarSuccess.component';
import ModalDisabledComponent from '../Modal/ModalDisabled/modalDisabled.component';
import ModalDisabledSuccessComponent from '../Modal/ModalDisabled/modalDisabledSuccess.component';
import ModalDeleteComponent from '../Modal/ModalDelete/modalDelete.component';
import ModalDeleteSuccessComponent from '../Modal/ModalDelete/modalDeleteSuccess.component';
import ModalOirsComponent from '../Modal/ModalOirs/modalOirs.component';
import ModalOirsSuccessComponent from '../Modal/ModalOirs/modalOirsSuccess.component';
import { IUser, UserStatus } from '../../../types/userInterface';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

type ActionsButtonComponentProps = {
  user: Partial<IUser>;
  updateAmiga: (user: Partial<IUser>) => Promise<void>;
  setError: (message: string) => void;
  setLoading: (loading: boolean) => void;
};

const ActionsButtonComponent: React.FC<ActionsButtonComponentProps> = ({
  user,
  updateAmiga,
  setError,
  setLoading,
}) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [modalShowAsignar, setModalShowAsignar] = useState(false);
  const [modalShowAsignarSuccess, setModalShowAsignarSuccess] = useState(false);
  const [modalShowCambiar, setModalShowCambiar] = useState(false);
  const [modalShowCambiarSuccess, setModalShowCambiarSuccess] = useState(false);
  const [modalShowOirs, setModalShowOirs] = useState(false);
  const [modalShowOirsSuccess, setModalShowOirsSuccess] = useState(false);
  const [modalShowDisabled, setModalShowDisabled] = useState(false);
  const [modalShowDisabledSuccess, setModalShowDisabledSuccess] =
    useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);
  const [modalShowDeleteSuccess, setModalShowDeleteSuccess] = useState(false);

  const handleClose = () => setShow(false);
  const navigate = useNavigate();

  const handleModalAsignarSuccessClose = () => {
    setModalShowAsignarSuccess(false);
    setShow(false);
  };

  // Cambiar
  const openModalCambiar = () => {
    setModalShowCambiar(true);
    setShow(false);
  };

  const handleModalCambiarSuccess = () => {
    setModalShowCambiar(false);
    setModalShowCambiarSuccess(true);
  };

  const handleModalCambiarClose = () => {
    setModalShowCambiar(false);
  };

  const handleModalCambiarSuccessClose = () => {
    setModalShowCambiarSuccess(false);
    setShow(false);
  };
  // OIRS
  const openModalOirs = () => {
    setModalShowOirs(true);
    setShow(false);
  };

  const handleModalOirsSuccess = () => {
    setModalShowOirs(false);
    setModalShowOirsSuccess(true);
  };

  const handleModalOirsClose = () => {
    setModalShowOirs(false);
  };

  const handleModalOirsSuccessClose = () => {
    setModalShowCambiarSuccess(false);
    setShow(false);
  };

  // Disabled
  const openModalDisabled = () => {
    setModalShowDisabled(true);
    setShow(false);
  };

  const handleModalDisabledClose = () => {
    setModalShowDisabled(false);
  };

  const handleModalDisabledSuccessClose = () => {
    setModalShowDisabledSuccess(false);
    setShow(false);
  };

  // Delete
  const openModalDelete = () => {
    setModalShowDelete(true);
    setShow(false);
  };

  const handleModalDeleteSuccess = () => {
    updateAmiga({
      _id: user._id,
      status: UserStatus.BLOCKED,
    })
      .then(() => {
        setModalShowDeleteSuccess(true);
        setModalShowDelete(false);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      });
  };

  const handleModalDeleteClose = () => {
    setModalShowDelete(false);
  };

  const handleModalDeleteSuccessClose = () => {
    setModalShowDeleteSuccess(false);
    setShow(false);
  };

  const handleOverlayClose = () => {
    handleClose();
  };

  return (
    <>
      <ModalAsignarComponent
        user={user}
        show={modalShowAsignar}
        onHide={() => setModalShowAsignar(false)}
        setLoading={setLoading}
        updateAmiga={updateAmiga}
        onSuccess={(assignedTo) => {
          setModalShowAsignar(false);
          Swal.fire({
            title: 'Se asignó acompañante con éxito',
            html: `
              Has asignado como 
              <strong>${assignedTo.principalRol}</strong> a 
              <strong>${assignedTo.username}</strong> 
              para acompañar a esta mujer
            `,
            icon: 'success',
            confirmButtonText: 'Aceptar',
          });
        }}
      />
      <ModalAsignarSuccessComponent
        show={modalShowAsignarSuccess}
        onHide={handleModalAsignarSuccessClose}
      />
      <ModalCambiarColectivaComponent
        show={modalShowCambiar}
        onHide={handleModalCambiarClose}
        onSuccess={handleModalCambiarSuccess}
      />
      <ModalCambiarColectivaSuccessComponent
        show={modalShowCambiarSuccess}
        onHide={handleModalCambiarSuccessClose}
      />
      <ModalOirsComponent
        show={modalShowOirs}
        onHide={handleModalOirsClose}
        onSuccess={handleModalOirsSuccess}
      />
      <ModalOirsSuccessComponent
        show={modalShowOirsSuccess}
        onHide={handleModalOirsSuccessClose}
      />
      <ModalDisabledComponent
        show={modalShowDisabled}
        onHide={handleModalDisabledClose}
        onSuccess={() => {
          setModalShowDisabled(false);
          setModalShowDisabledSuccess(true);
        }}
        updateAmiga={updateAmiga}
        user={user}
      />
      <ModalDisabledSuccessComponent
        show={modalShowDisabledSuccess}
        onHide={handleModalDisabledSuccessClose}
        user={user}
      />
      <ModalDeleteComponent
        show={modalShowDelete}
        onHide={handleModalDeleteClose}
        onSuccess={handleModalDeleteSuccess}
      />
      <ModalDeleteSuccessComponent
        show={modalShowDeleteSuccess}
        onHide={handleModalDeleteSuccessClose}
      />
      <button
        type="button"
        className="btn btn--type2 btn-270"
        ref={target}
        onClick={() => setShow(!show)}
      >
        + Más acciones
      </button>
      <Overlay
        target={target.current}
        show={show}
        placement={window.innerWidth < 768 ? 'bottom' : 'left'}
        rootClose
        onHide={handleOverlayClose}
      >
        <Tooltip className="tooltip--white">
          <ul className="tooltip-options">
            <li>
              <button
                type="button"
                onClick={() => {
                  setModalShowAsignar(true);
                  setShow(false);
                }}
              >
                <i className="icon icon--user-single"></i>
                Asignar profesional
              </button>
            </li>
            <li>
              <Link to={`/editar-usuario/${user._id}`}>
                <i className="icon icon--edit"></i>
                Editar información
              </Link>
            </li>
            <li>
              <button type="button" onClick={openModalCambiar}>
                <i className="icon icon--change"></i>
                Derivar a Las Amigas
              </button>
            </li>
            <li>
              <button type="button" onClick={openModalOirs}>
                <i className="icon icon--change"></i>
                Derivar a otra colectiva o a reclamo OIRS
              </button>
            </li>
            <li>
              <button type="button" onClick={openModalDisabled}>
                <i className="icon icon--remove"></i>
                {user.status === UserStatus.INACTIVE ? (
                  <>
                    <span className="text-green">Activar cuenta</span>
                  </>
                ) : (
                  <>
                    <span className="text-red">Desactivar cuenta</span>
                  </>
                )}
              </button>
            </li>
            <li>
              <button type="button" onClick={openModalDelete}>
                <i className="icon icon--delete"></i>
                <span className="text-red">Eliminar cuenta</span>
              </button>
            </li>
          </ul>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default ActionsButtonComponent;
