import React, { useState } from 'react';
import FooterShared from '../../../shared/footer.shared';
import HeaderAdminShared from '../../../shared/headerAdmin.shared';
import DonationsInitComponent from '../../../components/Elements/Donations/donationsInit.component';
import comunasData from '../../../utils/comunas.json';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DonationsBlockComponent from '../../../components/Elements/Donations/donationsBlock.component';
import { faker } from '@faker-js/faker';
import DonationTabsComponent from '../../../components/Elements/Donations/donationTabs.component';

interface Region {
  region: string;
  comunas: string[];
}

interface ComunasData {
  regiones: Region[];
}

const DonationsScreen: React.FC = () => {
  // Tipa explícitamente el JSON de comunas
  const data: ComunasData = comunasData;
  const [showDonationDetail, setShowDonationDetail] = useState(false);

  // Extrae solo las comunas del JSON
  const comunas = data.regiones.reduce((acc: string[], curr: Region) => {
    acc.push(...curr.comunas);
    return acc;
  }, []);

  const labels = ['DIC', 'ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN'];

  const dataGraphic = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        backgroundColor: '#E4A9BB',
      },
    ],
  };

  const handleShowDonationDetail = () => {
    setShowDonationDetail(true);
  };

  return (
    <>
      <HeaderAdminShared />
      <section className="module40 container">
        <div className="body-row row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/administrador-inicio" className="btn--back">
                  <i className="icon icon--back"></i> Regresar
                </a>
              </div>
              <div className="col-12 mb-3">
                <h1 className="mb-4">Documentos</h1>
              </div>
              <div className="col-12 mb-3">
                <section className="donate-banner">
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <h2 className="h3">Total recaudado:</h2>
                      <DonationsInitComponent
                        number={50100.5}
                        date="20 de septiembre 2021"
                        clp={true}
                        size="large"
                      />
                    </div>
                    <div className="col-lg-4 col-12">
                      <h2 className="h4">Recaudado en el mes:</h2>
                      <DonationsInitComponent
                        number={30000.5}
                        date="20 de septiembre 2021"
                        clp={false}
                        size="small"
                      />
                    </div>
                    <div className="col-lg-4 col-12">
                      <h2 className="h4">Recaudado esta semana:</h2>
                      <DonationsInitComponent
                        number={1230.5}
                        date="20 de septiembre 2021"
                        clp={false}
                        size="small"
                      />
                    </div>
                  </div>
                </section>
                <hr />
                <div className="form-row">
                  <label htmlFor="comuna" className="form-label label--icon">
                    Comuna
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip>Comunas disponibles.</Tooltip>}
                    >
                      <span className="material-icons-outlined icon">
                        help_outline
                      </span>
                    </OverlayTrigger>
                  </label>
                  <select
                    className="form-select requiredField"
                    aria-label="Tu comuna"
                    defaultValue={'default'}
                  >
                    <option value="default" disabled>
                      Todas
                    </option>
                    {comunas.map((comuna, index) => (
                      <option key={index} value={comuna}>
                        {comuna}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 mb-3">
                <h2 className="h3 mb-3">
                  Detalle en total de todas las Comunas
                </h2>
                <DonationsBlockComponent
                  quantity={10100}
                  donations={3400}
                  increment={80.1}
                  button={false}
                  graphic={true}
                  chartData={dataGraphic}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <section className="dashboard-card dashboard--5">
                    <h3 className="h4 text-icon">
                      <i className="icon icon--historial"></i> Historial de
                      apuntes
                    </h3>
                    <DonationTabsComponent
                      showDonationDetail={handleShowDonationDetail}
                    />
                  </section>
                </div>
                <div className="col-lg-6 col-12">
                  {showDonationDetail && (
                    <div
                      id="donation-detail-item"
                      className="dashboard-card dashboard--2"
                    >
                      <h3 className="h4 text-icon">
                        <i className="icon icon--historial"></i> Detalle del
                        documento
                      </h3>

                      <div className="donation-detail">
                        <div className="btn btn--list btn--disabled">
                          <div>
                            <i className="icon icon--coin"></i>
                            <span className="btn--list__label text-400">
                              Documento por paypal
                            </span>
                          </div>
                          <span className="btn--list__number">$180.00</span>
                        </div>
                        <hr />
                        <table className="table-user-data">
                          <tbody>
                            <tr>
                              <td>
                                <p className="data-info">Mujer:</p>
                              </td>
                              <td>Paulina Pimentel</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="data-info">Fecha:</p>
                              </td>
                              <td>28/Junio/2023</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="data-info">Hora:</p>
                              </td>
                              <td>18:34 hrs.</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="data-info">ID de transacción:</p>
                              </td>
                              <td>1123456931041384</td>
                            </tr>
                            <tr>
                              <td>
                                <p className="data-info">Tipo de donación:</p>
                              </td>
                              <td>Paypal</td>
                            </tr>

                            <tr>
                              <td>
                                <p className="data-info">Folio:</p>
                              </td>
                              <td>123456931041384</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="text-center d-flex justify-content-center">
                    <figure className="image-270">
                      <img
                        src="/assets/images/illustration/computer.png"
                        alt="illustrations"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default DonationsScreen;
