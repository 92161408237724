import React, { useState } from 'react';
import { Accordion, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  name: string;
  onChange: (data: BitacoraItemData) => void;
}

interface BitacoraItemData {
  id: string;
  date: string;
  hour: string; // Cambiamos el tipo a string para manejar el formato de tiempo
  note: string;
}

const BitacoraItemComponent: React.FC<Props> = ({ name, onChange }) => {
  const [date, setDate] = useState<string>('');
  const [hour, setHour] = useState<string>(''); // Cambiamos el estado a string
  const [note, setNote] = useState<string>('');

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setDate(newDate);
    onChange({ id: name, date: newDate, hour, note });
  };

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHour = e.target.value; // Esto ya es una cadena en formato HH:mm
    setHour(newHour);
    onChange({ id: name, date, hour: newHour, note });
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value;
    setNote(newNote);
    onChange({ id: name, date, hour, note: newNote });
  };

  return (
    <Accordion.Item eventKey="0">
      <Accordion.Header>{name}</Accordion.Header>
      <Accordion.Body className="pl-0 pr-0">
        <div className="form-content">
          <div className="form-row">
            <label htmlFor="date" className="form-label label--icon">
              Día
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    Día
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
              type="date"
              className="form-control form--gray"
              id="date"
              placeholder="dd/mm/aaaa"
              value={date}
              onChange={handleDateChange}
            />
          </div>
          <div className="form-row">
            <label htmlFor="hour" className="form-label label--icon">
              Hora
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    Hora
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <input
              type="time"
              className="form-control form--gray"
              id="hour"
              value={hour} // La hora ahora está en el formato HH:mm
              onChange={handleHourChange}
            />
          </div>
          <div className="form-row">
            <label htmlFor="note" className="form-label label--icon">
              Nota
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    Nota
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">help_outline</span>
              </OverlayTrigger>
            </label>
            <textarea
              className="form-control form--gray"
              id="note"
              placeholder=""
              rows={3}
              value={note}
              onChange={handleNoteChange}
            ></textarea>
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default BitacoraItemComponent;
