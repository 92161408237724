import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IUser, UserStatus } from '../../../../types/userInterface';

interface ModalDisabledSuccessComponentProps {
  user: Partial<IUser>;
  show: boolean;
  onHide: () => void;
}

const ModalDisabledSuccessComponent: React.FC<
  ModalDisabledSuccessComponentProps
> = ({ user, show, onHide }) => {
  const [personalData, setPersonalData] = useState<Partial<IUser>>({});

  useEffect(() => {
    if (user) {
      setPersonalData(user);
    }
  }, [user]);
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal--gray"
      >
        <Modal.Header className="justifify-content-center">
          <Modal.Title id="contained-modal-title-vcenter" className="col-12">
            <h2 className="text-center h3">
              ¡ Cuenta{' '}
              {`${
                personalData.status === UserStatus.ACTIVE
                  ? 'activada'
                  : 'desactivada'
              }`}{' '}
              !
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-12 d-flex justify-content-center">
            <figure className="image-179">
              <img
                src="/assets/images/illustrations/disabled.png"
                alt="Success"
              />
            </figure>
          </div>
          <p className="text-center">
            Se{' '}
            {`${
              personalData.status === UserStatus.ACTIVE ? 'activó' : 'desactivó'
            }`}{' '}
            la cuenta de:
          </p>
          <p className="text-center">
            <strong>{`${personalData.username || ''}`}</strong> (no. Ficha{' '}
            {`${personalData.userId}`})
          </p>
          <p className="mb-0 text-center">
            Los cambios ya podrán ser visibles en su ficha
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mb-3 col-12 d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn--type1 btn--290"
              onClick={onHide}
            >
              Regresar a perfil
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDisabledSuccessComponent;
