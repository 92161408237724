import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalDerivarProps {
  show: boolean;
  onHide: () => void;
  onEnd: () => void;
}

const ModalDerivarGrupoComponents: React.FC<ModalDerivarProps> = ({ show, onHide, onEnd }) => {


  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--derivar"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">La acompañante solicitó derivar a esta mujer a otras redes feministas</h2>
          <p className="text-24 text-center">Estos son los motivos expuestos por la acompañante:</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert--soft-success">
          <span className="material-icons-outlined">flag</span>
          <article>
            <p><strong>Derivar a otra colectiva u organización</strong></p>
          </article>
        </div>
        <ul className="check-list">
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Considera complicada la ubicación del hospital</p>
          </li>
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Por miedo a ser expulsada del hogar</p>
          </li>
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Miedo a ser juzgada y/o castigada por la familia</p>
          </li>
          <li>
            <span className="material-icons-outlined">done</span>
            <p>Otras razones</p>
          </li>
        </ul>
        <div className="alert alert--info">
          <article>
            <p className="text-gray600"><strong>Observaciones hechas por la especialista:</strong></p>
            <p>Atención psicológica Ingresa motivos adicionales o situaciones que puedan ayudar a Las Amigas a entender el por qué de esta decisión.</p>
          </article>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-md-9 mx-auto col-12 d-flex justify-content-center mb-3">
          
           <button type="submit" className="btn btn--type1 btn--100"
              onClick={() => {
                onHide();
                onEnd();
              }}
            >
              Aceptar y derivar a otra colectiva u organización
            </button>
         
        </div>
        <div className="col-md-9 mx-auto col-12 d-flex justify-content-center">
          <button type="button" className="btn btn--type2 btn--100" onClick={onHide}>
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDerivarGrupoComponents;
