import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface Step {
  title: string;
  description: string;
  status: string;
  completed: boolean;
  color: string;
}

interface ProcessStatusComponentProps {
  steps: Step[];
  currentStepIndex: number;
}

const ProcessStatusComponent: React.FC<ProcessStatusComponentProps> = ({
  steps,
  currentStepIndex,
}) => {
  // Calcular el porcentaje de progreso basado en el paso actual.
  const totalSteps = steps.length;
  const percentage = ((currentStepIndex + 1) / totalSteps) * 100;

  // Obtener el paso actual.
  const currentStep = steps[currentStepIndex];
  return (
    <>
      <div className="status-bar">
        {steps.map((step, index) => (
          <div key={index} className={`status-item ${step.status}`}>
            <div className="status-item__top">
              <div className="status-item__icon">
                {step.completed && <i className="fa-solid fa-check"></i>}
              </div>
              {index < steps.length - 1 && <hr />}
            </div>
            <article className="status-item__bottom text-500">
              <p>{step.title}</p>
              <p className={`text-${step.color}`}>{step.description}</p>
            </article>
          </div>
        ))}
      </div>
      <div className="status-bar__mobile">
        <div className="status-bar__circle">
          <span className="status-bar__step">
            {currentStepIndex + 1} de {totalSteps}
          </span>
          <CircularProgressbar
            value={percentage}
            strokeWidth={4}
            styles={buildStyles({
              pathColor: '#1F7164',
              trailColor: '#91D6C4',
            })}
          />
        </div>
        <article className="status-item__bottom text-500">
          <p className="text-black text-500">{currentStep.title}</p>
          <p className={`text-${currentStep.color}`}>
            {currentStep.description}
          </p>
        </article>
      </div>
    </>
  );
};

export default ProcessStatusComponent;
