import React from 'react';
import { Modal} from 'react-bootstrap';

interface ModalDeleteSuccessComponentProps {
  show: boolean;
  onHide: () => void;
}

const ModalDeleteSuccessComponent: React.FC<ModalDeleteSuccessComponentProps> = ({ show, onHide }) => {
  return (
    <>
      <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal--gray"
    >
     
      <Modal.Body>
      <div className="col-12 d-flex justify-content-center">
            <figure className="image-280">
              <img src="/assets/images/icons/checked.svg" alt="Success" />
            </figure>
          </div>
          <h2 className="text-40 text-center mb-4">Cuenta eliminada</h2>
          <p className="text-center text-24 mb-5">La cuenta de:</p>
          <p className="text-center text-24"><strong>PAULINA PIMENTEL (no. Ficha 273923)</strong></p>
          <p className="text-center text-24">fue eliminada de la base de datos.</p>
          
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <a href="/colectiva" className="btn btn--type1  btn--370">Regresar a la colectiva</a>
        </div>
       
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default  ModalDeleteSuccessComponent;