import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { IAdmin } from '../types/adminInterface';

const COOKIE_NAME = 'OlaAdminSessionCooke';

export const saveSession = async (user: Partial<IAdmin>) => {
  if (user) {
    const {
      _id,
      username,
      lastName,
      email,
      phone,
      password,
      isActive,
      createdAt,
      adminId,
      step,
      principalRol,
      secondaryRol,
      token,
    } = user;
    Cookies.set(
      COOKIE_NAME,
      JSON.stringify({
        _id,
        username,
        lastName,
        email,
        phone,
        password,
        isActive,
        createdAt,
        adminId,
        step,
        principalRol,
        secondaryRol,
        token,
      })
    );
  }
};

export const getSession = () => {
  const data = Cookies.get(COOKIE_NAME);
  if (!data) {
    return null;
  }
  const admin = JSON.parse(data) as IAdmin;
  const token = admin.token || '';
  const decodedToken = jwtDecode(token);
  const now = new Date();
  const exp = decodedToken.exp || 0;
  if (exp < now.getTime() / 1000) {
    return null;
  }
  return admin;
};

export const updateSession = (user: IAdmin) => {
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
};

export const deleteSession = () => {
  Cookies.remove(COOKIE_NAME);
};

export const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
