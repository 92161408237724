export const NACIONALIDAD_DATA = [
  {"id":"Argentina", "label": "Argentina"},
  {"id":"Brasil", "label": "Brasil"},
  {"id":"Bolivia", "label": "Bolivia"},
  {"id":"Chile", "label": "Chile"},
  {"id":"Colombia", "label": "Colombia"},
  {"id":"Ecuador", "label": "Ecuador"},
  {"id":"Haiti", "label": "Haití"},
  {"id":"Peru", "label": "Perú"},
  {"id":"Paraguay", "label": "Paraguay"},
  {"id":"Uruguay", "label": "Uruguay"},
  {"id":"Venezuela", "label": "Venezuela"},
  {"id":"Otro", "label": "Otro"}
]

