import React, { useState, useEffect } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import CustomPagination from '../Paginator/paginator.component';
import Accordion from 'react-bootstrap/Accordion';
import AccordionItem from 'react-bootstrap/AccordionItem';
import AccordionHeader from 'react-bootstrap/AccordionHeader';
import AccordionBody from 'react-bootstrap/AccordionBody';
import { IUser, UserSteps } from '../../../types/userInterface';
import { differenceInDays, format } from 'date-fns';

interface Props {
  users: IUser[];
  pagination?: {
    totalDocs: number;
    page: number;
    limit: number;
    totalPages: number;
  };
  setPagination?: React.Dispatch<
    React.SetStateAction<{
      totalDocs: number;
      page: number;
      limit: number;
      totalPages: number;
    }>
  >;
}

const GestoraTableComponent: React.FC<Props> = ({
  users,
  pagination,
  setPagination,
}) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1200);

  const columns: TableColumn<IUser>[] = [
    {
      name: 'No. Ficha',
      selector: (row) => row.userId,
    },
    {
      name: 'Nombre',
      selector: (row) => row.username,
      format: (row) => {
        const diffDays = differenceInDays(new Date(), new Date(row.createdAt));
        const isNew = diffDays <= 3;
        return (
          <a
            href={`/detalle-usuario/${row._id}`}
            className={`text-green400 text-uppercase ${isNew ? 'new' : ''}`}
          >
            <strong>{row.username}</strong>
          </a>
        );
      },
    },
    {
      name: 'Semanas',
      selector: (row) =>
        row.saludGinecologica.pregnancyCalculator?.result || 'N/A',
    },
    {
      name: 'Derivada de',
      selector: (row) => row.derivada.derivadaA || 'N/A',
    },

    {
      name: 'Estado',
      selector: (row) => row.step,
      format: ({ step }) => formatStatus(step),
    },
    {
      name: 'Hora de registro',
      selector: (row) => row.createdAt,
      format: ({ createdAt }) => {
        const time = format(new Date(createdAt), 'hh:mm a');
        const dateFormatted = format(new Date(createdAt), 'dd/MM/yy');
        return (
          <div className="data-table__time">
            <span style={{ marginRight: 8 }}>{time}</span>
            <span>{dateFormatted}</span>
          </div>
        );
      },
    },
  ];

  const formatStatus = (step: UserSteps) => {
    switch (step) {
      case UserSteps.MI_PROCESO:
        return (
          <span className="badge badge--status badge--status-success">
            En espera
          </span>
        );
      case UserSteps.ACOMPANAMIENTO:
        return (
          <span className="badge badge--status badge--status-success">
            Acompañamiento
          </span>
        );
      case UserSteps.ENTREGA:
        return (
          <span className="badge badge--status badge--status-done">
            Entrega
          </span>
        );
      case UserSteps.ENTREGA_SUCCESS:
        return (
          <span className="badge badge--status badge--status-info">
            Entrega Exitosa
          </span>
        );
      case UserSteps.PENDING_EVALUATION:
        return (
          <span className="badge badge--status badge--status-info">
            Pendiente de Evaluación
          </span>
        );
      default:
        return (
          <span className="badge badge--status badge--status-info">
            Pendiente de Evaluación
          </span>
        );
    }
  };

  const updateMedia = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div className="data-table">
      {!isMobileView ? (
        <DataTable
          columns={columns}
          data={users}
          pagination
          paginationComponent={() => {
            return (
              <div className="data-paginator">
                <CustomPagination
                  rowCount={pagination?.totalDocs || 0}
                  rowsPerPage={pagination?.limit || 0}
                  currentPage={pagination?.page || 0}
                  pagesToShow={pagination?.totalPages || 0}
                  onChangePage={(page: number) => {
                    if (pagination && setPagination) {
                      setPagination({
                        ...pagination,
                        page: page,
                      });
                    }
                  }}
                />
              </div>
            );
          }}
        />
      ) : (
        <section className="data-table-mobile">
          <div className="data-table-mobile__header">
            <p>
              <strong>Nombre</strong>
            </p>
          </div>
          <Accordion className="data-table-mobile__accordion">
            {users.map((row, index) =>
              (() => {
                const time = format(new Date(row.createdAt), 'hh:mm a');
                const dateFormatted = format(
                  new Date(row.createdAt),
                  'dd/MM/yy'
                );
                return (
                  <AccordionItem eventKey={String(index)} key={row.userId}>
                    <AccordionHeader>{row.username}</AccordionHeader>
                    <AccordionBody>
                      <table>
                        <tr>
                          <td>
                            <strong>No. Ficha:</strong>
                          </td>
                          <td className="text-center">{row.userId}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Semanas:</strong>
                          </td>
                          <td className="text-center">
                            {row.saludGinecologica.pregnancyCalculator?.result}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Derivada de:</strong>
                          </td>
                          <td className="text-center">
                            {row.derivada.derivadaA}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Estado:</strong>
                          </td>
                          <td className="text-center">
                            {formatStatus(row.step)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Hora de registro:</strong>
                          </td>
                          <td className="text-center">
                            <div className="data-table__time">
                              <span style={{ marginRight: 8 }}>{time}</span>
                              <span>{dateFormatted}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2} className="text-center">
                            <a
                              href="/detalle-usuario"
                              className="btn btn--type2"
                            >
                              <strong>Ver ficha</strong>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </AccordionBody>
                  </AccordionItem>
                );
              })()
            )}
          </Accordion>
        </section>
      )}
    </div>
  );
};

export default GestoraTableComponent;
