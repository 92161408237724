import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { getSession } from '../../../utils/helpers';
import { searchUsers } from '../../../api/usersApi';
import { searchAdmins } from '../../../api/adminApi';
import { AdminPrincipalRol, AdminSteps } from '../../../types/adminInterface';
import ColectivasTable from '../DataTable/colectivasTable.component';

interface ColectivaData {
  id?: number;
  name: string;
  women?: number;
  gestora?: string;
  asesora?: string;
  acompanantes?: string;
  psicologas?: string;
  aporte?: number;
  url?: string;
}

interface AccordionCompleteProps {
  zone: string;
  colectivas: ColectivaData[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: (message: string) => void;
}

const AccordionComplete: React.FC<AccordionCompleteProps> = ({
  zone,
  colectivas,
  setLoading,
  setError,
}) => {
  const [colectivasData, setColectivasData] = useState<ColectivaData[]>([]);

  const handleColectivaClick = async (
    colectivaId: number,
    colectiva: string
  ) => {
    const currentColectiva = colectivasData.find(
      (colectiva) => colectiva.id === colectivaId
    );
    if (currentColectiva?.women) {
      return;
    }
    try {
      setLoading(true);
      const session = getSession();
      const mujeres = await searchUsers(session?.token || '', {
        colectiva: { value: colectiva },
      });
      const admins = await searchAdmins(
        1,
        100,
        { colectiva: { name: colectiva }, stepGte: AdminSteps.GET_OLA_ACCOUNT },
        session?.token || ''
      );

      const cloneColectivasData = [
        ...colectivasData.map((colectiva) => {
          if (colectiva.id === colectivaId) {
            console.log('founded colectiva', { colectiva, mujeres, admins });
            const docs = admins.docs || [];
            const { GESTORA, ASESORA_LEGAL, ACOMPANANTE, PSICOLOGA } =
              AdminPrincipalRol;
            const newDoc = {
              ...colectiva,
              women: mujeres.totalDocs || 0,
              gestora: docs
                .filter(({ principalRol }) => principalRol === GESTORA)
                .map(({ username }) => username)
                .join(', '),
              asesora: docs
                .filter(({ principalRol }) => principalRol === ASESORA_LEGAL)
                .map(({ username }) => username)
                .join(', '),
              acompanantes: docs
                .filter(({ principalRol }) => principalRol === ACOMPANANTE)
                .map(({ username }) => username)
                .join(', '),
              psicologas: docs
                .filter(({ principalRol }) => principalRol === PSICOLOGA)
                .map(({ username }) => username)
                .join(', '),
              aporte: 0,
            } as ColectivaData;
            console.log(newDoc);
            return newDoc;
          }
          return {
            ...colectiva,
            women: 0,
            lider: '',
            acompanantes: '',
            talleristas: '',
            entregadoras: '',
            aporte: 0,
          };
        }),
      ];

      console.log(cloneColectivasData);
      setColectivasData(cloneColectivasData);
    } catch (error) {
      setError('Error al obtener los datos de la colectiva');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('useEffect colectivas', colectivas);
    setColectivasData(colectivas);
  }, []);

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="accordion-reset-padding">
          <span className="text-24">{zone}</span>
        </Accordion.Header>
        <Accordion.Body className="p-0">
          {colectivasData.map((colectiva, index) => (
            <Accordion
              className="accordion-item-inner"
              key={index}
              onClick={() => {
                handleColectivaClick(colectiva.id || 0, colectiva.name);
              }}
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <span className="text-20 text-green400 text-400 ph-10">
                    {colectiva.name}
                  </span>
                </Accordion.Header>
                <Accordion.Body className="pl-0 pr-0 pt-2 pb-2">
                  <ColectivasTable colectiva={colectiva} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccordionComplete;
