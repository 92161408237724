import React from 'react';
import HeaderShared from '../../../../shared/header.shared';
import FooterShared from '../../../../shared/footer.shared';
import CapacitacionComponent from '../../../../components/Forms/capacitacion/capacitacion.component';

const CapacitacionScreen: React.FC = () => {
  return (
    <>
      <HeaderShared />
      <section className="module40">
        <section className="container">
          <div className="body-row module-bottom">
            <div className="row">
              <div className="col-12 mb-4">
                <a href="/detalle-usuario" className="btn--back"><i className="icon icon--back"></i> Regresar</a>
              </div>
              <div className="col-lg-5 col-12">
                <h1 className="mb-3">Capacitación</h1>
                <article className="col-12 col-reset">
                  <p className="text-32 text-500 mb-0">Paulina Pimentel</p>
                  <p className="text-24 text-gray700 text-500">No. 273923</p>
                  <p>Fecha de ingreso: 30/09/23 </p>
                </article>
                <CapacitacionComponent />
              </div>
              <aside className="col-lg-7 col-12 d-flex justify-content-end hide-mobile">
                <figure className="image-270">
                  <img src="/assets/images/illustration/book.png" alt="Bitácora" />
                </figure>
              </aside>
            </div>
          </div>
        </section>
      </section>
      <FooterShared />
    </>
  );
};

export default CapacitacionScreen;