import React from 'react';
import Modal from 'react-bootstrap/Modal';

interface ModalNotesProps {
  show: boolean;
  onHide: () => void;
}
const ModalNotesComponentsSuccess: React.FC<ModalNotesProps & { onBackNote: () => void }> = ({ show, onHide, onBackNote }) => {
  const handleBackNote = () => {
    onBackNote(); 
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--checked"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Se ha agregado una nueva nota</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="text-24">La nota que agregaste se verá reflejada en la ficha de la mujer.</p>
        <p className="text-24">Esta información será visible <strong>dentro del equipo de OLA</strong>.</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button type="submit" className="btn btn--type1 " onClick={handleBackNote}>Agregar nueva nota</button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button type="button" className="btn--simple text-purple400" onClick={onHide}>Regresar a la ficha</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNotesComponentsSuccess;
