import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IUserNote } from '../../../../types/userInterface';
import { IAdmin } from '../../../../types/adminInterface';

interface ModalNotesProps {
  show: boolean;
  admin: Partial<IAdmin>;
  onHide: () => void;
  onAddNote: (note: IUserNote) => void;
}
const ModalNotesComponents: React.FC<ModalNotesProps> = ({
  show,
  admin,
  onHide,
  onAddNote,
}) => {
  const [note, setNote] = useState('');

  const isNoteEmpty = note.trim().length === 0;

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleAddNote = (note: string) => {
    onAddNote({
      note,
      adminId: admin._id || '',
      createdAt: new Date().toISOString(),
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="col-12 d-flex justify-content-center mb-4">
            <i className="icon icon--note"></i>
          </div>
          <h2 className="text-40 text-center text-500 pb-2">Agrega una nota</h2>
          <p className="text-24 text-center">
            Lleva un registro de la mujer que acompañas y compártelo con el
            equipo de Ola en su historial.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-row">
            <label className="form-label">Agrega una nota</label>
            <textarea
              className="form-control "
              placeholder="Ingresa la información a registrar de la mujer en este espacio"
              id="nota"
              value={note}
              onChange={handleNoteChange}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 d-flex justify-content-center mb-3">
          <button
            type="submit"
            className="btn btn--type1 btn--370"
            disabled={isNoteEmpty}
            onClick={(e) => {
              e.preventDefault();
              handleAddNote(note);
            }}
          >
            Agregar nota al expediente
          </button>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn--type3 btn--370"
            onClick={onHide}
          >
            Cancelar
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalNotesComponents;
