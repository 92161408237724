import React from 'react';

import { useLocation } from 'react-router-dom';
import HeaderShared from '../../../shared/header.shared';
import { IAdmin } from '../../../types/adminInterface';
import FooterShared from '../../../shared/footer.shared';

const NewAmigaSuccessScreen: React.FC = () => {
  const { state } = useLocation();
  const admin = state.admin as IAdmin | undefined;
  return (
    <>
      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-lg-10 col-12 mx-auto text-center">
                <div className="d-flex justify-content-center">
                  <figure className="image-280">
                    <img
                      src="/assets/images/illustrations/about.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                <h1 className="text-40 mb-3">
                  ¡ Nueva acompañante registrada con éxito !
                </h1>
                <p>
                  Se registró una nueva acompañante en la colectiva{' '}
                  <strong>{`${admin?.city?.name?.toUpperCase() || ''}`}</strong>
                  , ya puedes comenzar a asiganarla a las mujeres de la
                  colectiva
                </p>
                <p>
                  La nueva acompañante podrá acceder a la plataforma con el
                  correo electrónico <strong>{`${admin?.email || ''}`}</strong>{' '}
                  y la contraseña <br />
                  <br />
                  <strong className="text-green300 text-32">{`${
                    admin?.password || ''
                  }`}</strong>
                  <br />
                </p>
                <p>Guarda esta información ya que sólo se mostrará una vez.</p>
              </div>
            </div>
            <div className="form-row  form--last">
              <a
                href="/administrador-inicio"
                className="btn btn--type1 btn--270"
              >
                Ir al Inicio
              </a>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default NewAmigaSuccessScreen;
